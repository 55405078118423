import DefaultIntroIcon from "../Icons/DefaultIntroIcon";
import ScenarioIcon1 from "../Icons/Scenarios/ScenarioIcon1";
import ScenarioIcon2 from "../Icons/Scenarios/ScenarioIcon2";
import ScenarioIcon3 from "../Icons/Scenarios/ScenarioIcon3";
import ScenarioIcon4 from "../Icons/Scenarios/ScenarioIcon4";
import ScenarioIcon5 from "../Icons/Scenarios/ScenarioIcon5";
import ScenarioIcon6 from "../Icons/Scenarios/ScenarioIcon6";
import ScenarioIcon7 from "../Icons/Scenarios/ScenarioIcon7";
import ScenarioIcon8 from "../Icons/Scenarios/ScenarioIcon8";

export function ScenerioIconByIndex({ScenerioIconIndex}) {

  function addScenarioIcon(index) {
    switch(index) {
      case 1:
        return <ScenarioIcon1/>
      case 2:
        return <ScenarioIcon2/>
      case 3:
        return <ScenarioIcon3/>
      case 4:
        return <ScenarioIcon4/>
      case 5:
        return <ScenarioIcon5/>
      case 6:
        return <ScenarioIcon6/>
      case 7:
        return <ScenarioIcon7/>
      case 8:
        return <ScenarioIcon8/>
      default:
        return <DefaultIntroIcon/>
    }
  }

  return(
    <>
      {addScenarioIcon(ScenerioIconIndex)}
    </>
    );
}