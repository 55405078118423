
import { Fade } from "react-awesome-reveal";
import { allScenarios } from "../../utils/allScenarios";
import { Link } from "react-router-dom";
import CheckIcon from "../Icons/CheckIcon";
import { useLocalStorage } from "../../useLocalStorage";
import { ScenerioIconByIndex } from "../Main/ScenerioIconByIndex";
import Finish from "../Summary/Finish";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

const Scenarios = () => {

  const results = useLocalStorage('scenario');
  let scenerioDataArr = [];
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  if (localStorage.getItem("scenario") !== undefined) {
    scenerioDataArr = JSON.parse(results[0]);
  }

  function createScenarioStatus() {
    let status = new Array(8).fill(false);
    scenerioDataArr.forEach((e, index) => {
      if (e === 0) {
        if (index < 4) {
          status[0] = true;
        }
        else if (index < 8) {
          status[1] = true;
        }
        else if (index < 13) {
          status[2] = true;
        }
        else if (index < 18) {
          status[3] = true;
        }
        else if (index < 23) {
          status[4] = true;
        }
        else if (index < 28) {
          status[5] = true;
        }
        else if (index < 32) {
          status[6] = true;
        }
        else {
          status[7] = true;
        }
      }
    })
    return status;
  }

  let stats = createScenarioStatus();

  return (
    <div className="scenerios-container flex flex-row flex-wrap justify-center 2xl:h-screen content-center mt-12 2xl:mt-0">
      <div className="text-center m-auto w-full">
        <h1 id="headingScenarios" className="text-4xl font-extrabold mt-5 text-accent uppercase w-full" tabIndex={0}>{i18n.t("scenarios_heading")}</h1>
        <div className=" text-center m-auto mt-3 -mb-5 mx-2">
          <p>{i18n.t("scenarios_instruction")}</p>
          <div className=" text-center m-auto mt-6 -mb-12 mx-2">{stats.some((element) => element === true) ? "" : <Finish /> }</div>
        </div>
        
      </div>

      {/* <div className="flex flex-wrap justify-center gap-4 mt-10 mb-10 p-4"> */}
      <div className="hero-content grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4 justify-center mt-10 mb-10 p-4 m-auto w-full">
        <Fade>
          {allScenarios.map(({ name, person, text }, index) => {
            return (
              <div key={index + "_recommendation" + index}>
                <Link
                  className={`block rounded-none bg-white border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring ${stats[index] ? "" : '-pointer-events-none opacity-50 -cursor-not-allowed'}`}
                  to={"/recommendation/" + (index + 1)}>

                  {stats[index] ? "" : <CheckIcon />}

                  <span className="inline-block rounded-none bg-gray-50 p-3">
                    <ScenerioIconByIndex ScenerioIconIndex={index + 1} />
                  </span>

                  <p className="text-right float-right text-sm pt-3 text-gray-600">{person}</p>
                  <h2 className="mt-2 font-bold text-primary uppercase ">{i18n.t("scenario")} {index + 1}</h2>

                  <p className=" sm:mt-1 sm:block text-sm text-gray-600 min-h-12 pr-12">
                    {i18n.t(name)}
                  </p>
                </Link>
              </div>
            );
          })}
        </Fade>
      </div>
      {/*</div>*/}
      {stats.some((element) => element === true) ? "" : <Finish />}
    </div>

  )
}

export default Scenarios;