import { allRecommendations } from "../../utils/allRecommendations";
import { Fade } from "react-awesome-reveal";
import { ScenerioIconByIndex } from "./ScenerioIconByIndex";
import i18n from 'i18next';

export default function Intro({ handleClickScroll }) {

  return (
    <section id="hero" >
      <div className="flex justify-center items-center max-w-screen-2xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 mx-auto 2xl:h-screen">
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
          <div className="mx-auto max-w-lg text-center lg:mx-0 lg:text-left" >
            <h2 className="text-3xl font-bold sm:text-4xl sm:mt-10 mt-12 pt-3 prose uppercase text-accent select-none">
              {i18n.t("intro_title")}
            </h2>

            <p className="mt-4 text-gray-600 select-none">
              {i18n.t("intro_introduction")}
            </p>
            <ul className="steps steps-vertical text-gray-600 select-none">
              <li className="step step-primary !text-left">
                {i18n.t("intro_li_1")}
              </li>
              <li className="step hover:step-primary !text-left">
                {i18n.t("intro_li_2")}
              </li>
              <li className="step hover:step-primary !text-left">
                {i18n.t("intro_li_3")}
              </li>
              <li className="step hover:step-primary !text-left">
                {i18n.t("intro_li_4")}
              </li>
            </ul>
            <p className="mt-4 text-gray-600 select-none">
              {i18n.t("intro_outro")}
            </p>

            {/*<Link to="/scenarios/#"> */}
              <button onClick={handleClickScroll} className="btn btn-primary rounded-none mt-12 mb-10 sm:mb-10 sm:mt-8 inline-block px-12 py-3 text-sm font-medium text-white transition hover:btn-secondary focus:outline-none focus:ring focus:ring-yellow-400">
                {i18n.t("intro_submit")}
              </button>
             {/*</Link>*/}

          </div>

          <div className="grid grid-cols-2 gap-4 ">
            <Fade cascade damping={0.1}>
              {allRecommendations.map((recommendation, index) => {
                return (
                  <div key={index + "_recommendation"}
                    className="block rounded-none bg-white border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring select-none">

                    <span className="inline-block rounded-lg bg-gray-50 p-3">
                      <ScenerioIconByIndex ScenerioIconIndex={index + 1} />
                    </span>

                    <p className="text-right float-right text-sm ">{recommendation.user}</p>
                    <h2 className="mt-2 font-bold text-primary uppercase">{i18n.t("scenario")} {index + 1}</h2>

                    <p className=" sm:mt-1 sm:block text-sm text-gray-600">
                      {i18n.t(recommendation.name)}
                    </p>
                  </div>
                )
              })}
            </Fade>
          </div>
        </div>
      </div>

    </section>
  );
}