/* This will be the feedback area */
/* Graph plus text based summary */
/* This will be the page collecting these views together */

import Graph from "./Graph"
import SummaryFeedback from "./SummaryFeedback"
import { useLocalStorage } from "../../useLocalStorage";
import { Button } from "react-daisyui";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { PrinterIcon } from "@heroicons/react/20/solid";

export default function Summary({scenario}) {

  const results =  useLocalStorage('scenario');
  let scenerioDataArr = [];
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  if (localStorage.getItem("scenario") !== undefined) {
    if (results[0] === undefined) {
      scenerioDataArr = JSON.stringify(new Array(36).fill(0));
    }
    else {
      scenerioDataArr = JSON.parse(results[0]);
    }
  }

  if (Math.min(...scenerioDataArr) === 0) {
    return(
      <div id="error-page">
        <div id="hero" className="hero min-h-screen bg-base-200">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <div className="prose max-w-none text-center">
                <h1 className="text-2xl font-extrabold  my-3 text-accent uppercase">
                  {i18n.t("summary_error_title")}
                </h1>
                <p>{i18n.t("summary_error_text")}</p>
                <Link to="/scenarios">
                  <Button>{i18n.t("summary_error_btn")}</Button>
                </Link>
              </div>
           </div>
          </div>
        </div>
      </div>
    );

  } else if (Math.min(...scenerioDataArr) > 0) {
    return(
      <>
      <button id="print" className="swap btn flex !rounded-full bg-white mr-[148px] !z-[1]" onClick={ () => window.print() }><PrinterIcon className="w-5 h-5 flex p-0 text-primary"></PrinterIcon>{`${i18n.language === 'en' ? "Print results" : "Prindi tulemused"}`}</button>
        <h1 className="text-3xl font-bold text-center my-3 text-accent mb-3 mt-[94px] px-3 uppercase">
          {i18n.t("summary_title")}
        </h1>
        <Graph />
        <h2 className="text-xl font-bold text-center my-3 mt-10 px-3">
          {i18n.t("summary_preblocks_text")}
        </h2>
        <div className="columns-1 md:columns-2 p-5">
          <SummaryFeedback />
        </div>
      </>
    );
  }
 }