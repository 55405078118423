import { Fade } from "react-awesome-reveal";
import { allRecommendations } from "../../utils/allRecommendations";
import { useParams, Outlet, Link } from "react-router-dom";
import { allScenarios } from "../../utils/allScenarios";
import { ScenerioIconByIndex } from "../Main/ScenerioIconByIndex";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";


const Recommendations = () => {
  const { scenerioIndex } = useParams();
  let scenerioIndexToNum = Number(scenerioIndex) - 1;
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  return (
    <Fade>
    <div className="flex flex-wrap flex-grow-0 justify-center gap-4 p-5 2xl:h-screen items-center">
      <div className="card w-100 max-w-4xl my-3 mx-auto md:mx-6 block rounded-none bg-white border border-gray-100  p-1 md:p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
        <div className="card-body p-4">
          <div className="flex">
            <div className="flex content-center items-center mr-3">
              <span className="inline-block rounded-none bg-gray-50 p-3">
                <ScenerioIconByIndex ScenerioIconIndex={scenerioIndexToNum + 1}/>
              </span>
            </div>
            <div>
              <h2 className="text-primary uppercase font-extrabold">{i18n.t("scenario")} {scenerioIndexToNum + 1}</h2>
              <h4 className="text-xl text-accent uppercase font-extrabold ">{i18n.t(allRecommendations[scenerioIndexToNum].name)}</h4>
            </div>
          </div>
          <hr></hr>
          <p className="mt-2 mb-2">{i18n.t(allScenarios[scenerioIndexToNum].text)}</p>

          <hr></hr>

          <h4 className="text-neutral uppercase font-extrabold">{i18n.t("recommendation_steps_title")}</h4>


          <p>{i18n.t("recommendation_steps_instruction")}</p>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button>
                  <div className="btn btn-default float-left transform ease-in-out text-left">
                    {open ? i18n.t("recommendation_close_accordion") : i18n.t("recommendation_open_accordion") }
                    <ChevronRightIcon className={`${open ? "text-sm rotate-90 transform ease-in-out": ""} h-5 w-5`} />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="basis-full md:basis-1/2 mt-3">
                    <ul id="stuff" className="list-disc pl-10">

                      {allScenarios[scenerioIndex-1].recommendations.map((element,index) => {
                        return (
                          <li key={"rec" + index} className="list-disc pb-2">{i18n.t(element)}</li>
                        );
                      })}

                    </ul>
                    <div className="card-actions justify-start mb-5 mt-5">
                      <Link to={'/scenario/' +  (scenerioIndexToNum + 1)}>
                        <button className="btn btn-primary text-white">{i18n.t("recommendation_forward_btn")}</button>
                      </Link>
                    </div>
                  </div>

                </Disclosure.Panel>
              </>
            )}
            </Disclosure>
          <Outlet/>
        </div>

      </div>
    </div>
    </Fade>
  );
}

export default Recommendations;