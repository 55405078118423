import { useState } from "react";
import { useLocalStorage } from "../../useLocalStorage"
import { checkboxOptions } from "../../utils/checkboxOptions"
import { allScenariosDecisions } from "../../utils/allScenariosDecisions";
import { allRecommendations } from "../../utils/allRecommendations";
import { Slide } from "react-awesome-reveal";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";



import { Tab, Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { NavLink, useParams } from "react-router-dom";
import { ScenerioIconByIndex } from "../Main/ScenerioIconByIndex";


const ScenarioCheckboxInput = () => {

  const { scenerioIndex } = useParams();
  let scenerioIndexToNum = Number(scenerioIndex);
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  let largeSet = false;
  let tabsSize = 4;

  if (scenerioIndexToNum >= 3 && scenerioIndexToNum <= 6) {
    largeSet = true;
    tabsSize = 5;
  }
  let justIndex = 0;
  const [scenario, setScenario] = useLocalStorage('scenario', JSON.stringify(new Array(36).fill(0)));
  const [selected, setSelected] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleOnChange = (position) => {

    let item = position.split("s");
    let pos = item[1].split("_");

    let val = Number(pos[2]);
    /* 2_3 => (2-1) * 4 + 3 = 7 */

    let indexLocalStorage = (Number(pos[0]) - 1) * 4 + Number(pos[1]);
    if (!largeSet) {
      /* This is a wierd hack.. there should be a better way for this. I need to move after five ending
       * types add +4 in order to get to right position
       */
      if (scenerioIndexToNum > 6) {
        indexLocalStorage = (Number(pos[0]) - 1) * 4 + Number(pos[1]) + 4;
      }
    }
    if (largeSet) {
      indexLocalStorage = ((Number(pos[0]) - 1) * 5 + Number(pos[1])) - 2;
    }

    let valArray = JSON.parse(scenario);
    valArray[indexLocalStorage - 1] = val;

    setScenario(JSON.stringify(valArray));
    //setSelected(true);
  };

  return (

    <div className="card w-100 max-w-4xl my-3 mx-auto  2xl:h-screen flex items-center justify-center flex-nowrap flex-row">

      <div className="card-body bg-white rounded-none border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring overflow-hidden">
        <div className="card-title" tabIndex={0}>
          <div className="flex mb-6">
            <div className="flex content-center items-center mr-3">
              <span className="inline-block rounded-none bg-gray-50 p-3">
                <ScenerioIconByIndex ScenerioIconIndex={scenerioIndexToNum} />
              </span>
            </div>
            <div>
              <h3 className="text-sm text-secondary font-medium uppercase">{i18n.t("self_evalution")}</h3>
              <h2 className="text-primary uppercase font-extrabold">{i18n.t("scenario")} {scenerioIndexToNum}</h2>

              {/*<h4 className="text-xl text-accent font-extrabold uppercase">{i18n.t(allRecommendations[scenerioIndexToNum-1].name)}</h4>
              <h6 className="prose prose-sm">{i18n.t(allRecommendations[scenerioIndexToNum-1].name + "_text")}</h6>
              */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="text-xl text-accent font-extrabold uppercase text-left leading-tight">
                      {i18n.t(allRecommendations[scenerioIndexToNum - 1].name)}
                      <ChevronRightIcon className={`${open ? "text-sm rotate-90 transform ease-in-out" : ""} inline-block h-5 w-5 ml-1 bg-stone-100`} />
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[900ms] delay-[100ms]"
                      enterFrom="transform  max-h-0"
                      enterTo="transform  max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[600ms]"
                      leaveFrom="transform  max-h-[1000px]"
                      leaveTo="transform  max-h-0"
                    >
                      <Disclosure.Panel className="">
                        <p className="text-base font-normal">{i18n.t(allRecommendations[scenerioIndexToNum - 1].name + "_text")}</p>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
        <div>


          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="steps w-full transition">

              {Array.apply(0, new Array(tabsSize)).map(function (x, i) {
                return (
                  <Tab as="div"
                    key={'tag-' + i} id={'step-' + (i + 1)} className={({ selected }) =>
                      classNames(
                        'step after:text-white transition pointer-events-none	',
                        selected
                          ? 'step-neutral'
                          : `hover:step-neutral ${i + 1 > selectedIndex ? 'wut' : 'step-neutral'}`
                      )}>
                  </Tab>
                );
              })}
            </Tab.List>

            <Tab.Panels>

              {allScenariosDecisions.filter(decision => decision.id.includes("s" + scenerioIndexToNum + "_")).map(
                filteredDecision => (

                  <Tab.Panel key={filteredDecision.id} >
                    <Slide>
                      <h3 className="mb-3 mt-6 font-bold text-l">
                        {i18n.t(filteredDecision.decision)}
                      </h3>
                    </Slide>
                    <i className="hidden">{justIndex++}</i>
                    <div className="flex flex-wrap">
                      <Slide cascade damping={0.1} className="basis-full md:basis-1/2">
                        {checkboxOptions.map(({ name, value }, index) => {
                          // Set of special indexing for gathering info from local storage
                          let localStorageIndex = 0;
                          if (!largeSet) {
                            if (justIndex > 4) justIndex = 1;

                            localStorageIndex = ((scenerioIndexToNum - 1) * tabsSize + justIndex) - 1;

                            if (scenerioIndexToNum > 6) {
                              // Just a reminder of what this is connected to logically - look up in code
                              // (Number(pos[0])-1) * 4 + Number(pos[1]) + 4;
                              localStorageIndex = ((scenerioIndexToNum - 1) * tabsSize + justIndex) + 3;
                            }
                          }
                          if (largeSet) {
                            if (justIndex > 5) justIndex = 1;
                            localStorageIndex = ((scenerioIndexToNum - 1) * tabsSize + justIndex) - 3;
                          }
                          return (
                            <div key={"s" + scenerioIndexToNum + "_" + index + "_" + value}
                              className="block rounded-none border bg-white border-gray-100 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 hover:bg-gray-100 has-[:checked]:bg-gray-100 focus:outline-none focus:ring m-1">
                              <input
                                id={`${"s" + scenerioIndexToNum + "_" + justIndex + "_" + value}`}
                                type="radio"
                                value={value}
                                name={"s" + scenerioIndexToNum + "_" + justIndex}
                                onChange={(e) => handleOnChange(e.target.id)}
                                onClick={(e) => setSelected(true)}
                                className="w-4 h-4 -mr-1 ml-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
                                checked={JSON.parse(scenario)[localStorageIndex] === value}
                              />
                              <label
                                htmlFor={`${"s" + scenerioIndexToNum + "_" + justIndex + "_" + value}`}
                                className="p-4 py-4 pr-6 w-[calc(100%-2rem)] inline-block text-sm font-medium dark:text-gray-300">
                                {i18n.t(name)}
                              </label>
                            </div>
                          );
                        })}
                      </Slide>
                    </div>
                    <div className="card-actions justify-start mt-5">
                      <button disabled={!selected} className={`btn btn-primary text-white ${selected ? "" : "cursor-not-allowed"} ${selectedIndex === (tabsSize - 1) ? "hidden" : ""}`}
                        onClick={() => {
                          selectedIndex === (tabsSize - 1) ? setSelectedIndex((tabsSize - 1)) : setSelectedIndex(selectedIndex + 1);
                          setSelected(false);
                        }
                        }>{i18n.t("forward")}</button>
                      <NavLink to="/scenarios"
                        onClick={(e) => {
                          if (!selected) {
                            e.preventDefault();
                          }
                        }
                        } >
                        <button disabled={!selected} className={`btn btn-success text-white ${selectedIndex === (tabsSize - 1) ? "" : "hidden"}`}
                          onClick={() => {
                            setSelected(false);
                          }
                          }>{i18n.t("save")}</button>
                      </NavLink>
                    </div>

                  </Tab.Panel>

                ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  )
}

export default ScenarioCheckboxInput;
