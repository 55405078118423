import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "o4" : "I will definitely do it",
      "o3" : "I will probably do it",
      "o2" : "I will probably not do it",
      "o1" : "I will definitely not do it",

      "c1" : "Self-management",
      "c2" : "Communication",
      "c3" : "Cooperation",
      "c4" : "Problem solving",
      "c5" : "Creativity",
      "c6" : "Critical thinking",
      "c7" : "Digital skills",
      "c8" : "Entrepreneurship",
      "c9" : "Sustainable mindset",

      "c1_feedback_bad" : "Developing self-management skills is an important process that requires constant attention and practice. Time management and setting priorities can sometimes seem challenging, but developing these skills is essential. Finding motivation and managing stress are also crucial aspects that require consistent practice. The university offers excellent opportunities for developing self-management skills by providing various activities and resources. By taking advantage of the opportunities offered by the university, everyone can significantly improve and strengthen their self-management skills.",
      "c1_feedback_good" : "Good self-management skills provide a significant advantage. They include excellent time management and priority-setting abilities. A person who possesses these skills can motivate themselves and effectively manage their emotions. For them, managing stress is routine, and personal development is a key priority. Such skills benefit academic and professional growth, helping to succeed in various life areas.",

      "c2_feedback_bad" : "Developing communication skills is important, especially if they need to be fully mastered. Successful communication requires clarity, persuasiveness, active listening, and avoiding conflicts. Structured argumentation is also important. Developing these skills improves communication with different target groups and helps build trustworthy relationships across cultures. Continuous development of communication skills, practicing empathy, and refining conflict resolution skills open up new opportunities.",
      "c2_feedback_good": "Outstanding communication skills, including proficient verbal and written self-expression, are remarkable. Active listening and empathy are crucial in resolving conflicts and interacting with representatives of different cultures. The ability to effectively argue and present viewpoints convincingly is also valued, especially in diverse linguistic and cultural contexts. These skills are critically important for both academic and professional development.",
      
      "c3_feedback_bad" : "Developing cooperation skills, particularly involving different parties and enhancing empathy, is important. It offers the opportunity to discover new methods for engaging others and improving understanding. Listening and comprehension skills are essential for increasing team productivity. Developing skills in teamwork and conflict resolution, even if previously overlooked, opens up new opportunities for advancement in these areas. The daily application of these skills leads to rapid progress in teamwork.",
      "c3_feedback_good" : "Outstanding cooperation skills often or always manifest, including excellent listening skills and empathetic attitudes toward others, actively involving them in teamwork. Teamwork is natural, contributing to the smooth functioning of the team. Effectiveness in resolving conflicts and the ability to find compromises are crucial. These skills are critically important for successful teamwork and problem-solving.",
      
      "c4_feedback_bad" : "Problem solving is an essential skill, offering the opportunity to discover and develop analytical thinking, creativity, and strategic approaches. Although these skills may not be at the forefront, the potential to apply hidden talents and ideas to solve problems is significant. Focusing on prioritizing problems and finding effective solutions helps to notice progress in skill development. Evaluating results and consistently problem-solving are valuable skills that can be successfully developed and turned into strengths.",
      "c4_feedback_good" : "The skill of problem solving is impressive, encompassing the clear definition of problems and finding innovative solutions. The ability to handle uncertainty well, along with analytical, creative, and strategic thinking, is highly valued. Decisions made are always well-thought-out, emphasizing the assessment of applications and results and consistency in problem-solving.",
      
      "c5_feedback_bad" : "Creativity and innovativeness are important skills that help find novel solutions and generate original ideas. Although seeking alternative approaches is not always a priority, it reflects curiosity and ingenuity. The lack of creativity in problem-solving can hinder innovative responses to challenges, limiting the significance of achievable results. Therefore, it is important to focus on developing creativity and innovativeness to achieve greater progress in the future.",
      "c5_feedback_good" : "Creativity and innovativeness are outstanding qualities manifest in finding novel solutions and presenting original ideas. The ability to seek alternative approaches reflects curiosity and ingenuity. A creative approach to problem-solving is a strength that helps address challenges, innovatively leading to significant results. Continuous development in this direction is an excellent foundation for future achievements.",
      
      "c6_feedback_bad" : "The skill of critical thinking requires attention. Focusing on deep analysis, making well-argued decisions, asking questions, and considering different perspectives is important. Conscious development in this field enables deeper analysis and logical conclusions, enriching everyday thinking. Experimentation and continuous development in this direction are crucial to enhancing critical thinking as an essential aspect of life.",
      "c6_feedback_good" : "Critical thinking skill is precious, manifesting in thorough situation analysis, careful evaluation of different perspectives, and well-argued, evidence-based decisions. Effective problem identification, questioning, and understanding of situations are well-developed skills. Making logical conclusions, using analytical thinking, and critically assessing situations are also part of this strength. Continuous development and focus on enhancing critical thinking skills are essential for further progress.",
      
      "c7_feedback_bad" : "Developing digital skills is currently an excellent opportunity for personal growth. Although there is room for expansion in these areas, it presents an exciting challenge rather than an obstacle. The first steps in using digital environments and technologies open up new possibilities in everyday life. Searching for data and evaluating its reliability becomes increasingly easier, helping to stay informed. Exploring the principles of creating digital content and security reveals an exciting world that enriches one's skill set. Being open to new knowledge allows for the enjoyment of discovering the digital world and further developing one's skills.",
      "c7_feedback_good" : "Digital skills are at a high level. Software is used skillfully, and integrating technology into everyday life is effective. The use of digital environments is efficient, and the creation of quality digital content is a skill that receives attention. Adhering to the principles of digital security is also important. There are many opportunities for evaluating and developing digital skills, allowing these skills to be further refined.",
      
      "c8_feedback_bad" : "Developing entrepreneurial skills is an important ability that opens doors for personal and professional growth. Proactivity, quick action, and risk-taking are key qualities that help shape the future and make you an influential leader. Strategic thinking, innovation, and communication skills are crucial for enhancing a company's sustainability and competitiveness. Developing entrepreneurial skills allows you to make significant progress and enjoy your journey.",
      "c8_feedback_good" : "Entrepreneurial skills are excellent, demonstrating proactivity and readiness for quick action in strategically guiding the company. Promoting a sustainable mindset within the company and finding new suppliers and partners are also strong points. Creative thinking and innovation enable finding alternative solutions, fostering product development and service offerings. Excellent communication skills within the team and with external partners help effectively solve entrepreneurship-related problems.",
      
      "c9_feedback_bad" : "A sustainable mindset is an important skill that helps shape the world and evaluate the impact of one's actions. Environmental issues may seem secondary, but there is a great opportunity to develop in this field. The impact of a sustainable mindset extends beyond everyday life, offering enriching experiences in both personal life and academic and professional development. Developing this skill opens new doors and opportunities in university and future careers. Being open to new perspectives allows for taking firm steps toward a more sustainable future.",
      "c9_feedback_good" : "The skill of having a sustainable mindset is increasingly valued. It involves promoting environmentally friendly products and solutions, as well as actively participating in the development and implementation of sustainability strategies. A sustainable mindset is an integral part of culture and values. Companies analyze customers' and the market's views on sustainability, raise awareness of environmental and sustainability issues, and often voluntarily take a leading role in social and environmental responsibility.",
      
      "c1_tag1" : "self-regulation",
      "c1_tag2" : "adaptability",
      "c1_tag3" : "learning skills",
      "c2_tag1" : "self-expression",
      "c2_tag2" : "active listening",
      "c2_tag3" : "intercultural communication",
      "c3_tag1" : "involve",
      "c3_tag2" : "empathy",
      "c3_tag3" : "leadership and negotiation skill",
      "c4_tag1" : "problem identification",
      "c4_tag2" : "solution-oriented thinking",
      "c4_tag3" : "dealing with uncertainty",
      "c5_tag1" : "curiosity",
      "c5_tag2" : "innovativeness",
      "c5_tag3" : "resourcefulness",
      "c6_tag1" : "analytical thinking",
      "c6_tag2" : "asking questions",
      "c6_tag3" : "sense-making",
      "c7_tag1" : "using digital environments",
      "c7_tag2" : "creating digital content",
      "c7_tag3" : "digital security",
      "c8_tag1" : "readiness to act",
      "c8_tag2" : "risk-taking",
      "c8_tag3" : "proactivity",
      "c9_tag1" : "understanding sustainable development",
      "c9_tag2" : "evaluating the impact of your actions",
      "c9_tag3" : "future orientation",
      
      
      "s1_decision_1" : "I gather information and am oriented towards finding a solution.",
      "s1_decision_2" : "I carefully consider and evaluate the pros and cons of different options.",
      "s1_decision_3" : "I take the time to find the option from which I can learn the most.",
      "s1_decision_4" : "Regardless of the chosen solution, I strive to respond to all parties memorably and professionally.",
      
      "s2_decision_1" : "If necessary, I find the motivation and complete the entire presentation myself.",
      "s2_decision_2" : "I communicate empathetically with my colleague to get to the root of the problem and brainstorm together how to complete the presentation jointly.",
      "s2_decision_3" : "After the presentation, I analyze why such a situation occurred and what I could have done differently.",
      "s2_decision_4" : "I suggest different solutions for resolving the situation.",
      
      "s3_decision_1" : "I avoid advertising environmentally harmful products because I understand their environmental impact.",
      "s3_decision_2" : "I carefully consider the possible consequences of different solutions and create an appropriate plan based on that.",
      "s3_decision_3" : "I gather relevant information to analyze the product's environmental impact and reach well-founded conclusions.",
      "s3_decision_4" : "I act proactively (without being asked by the company manager) to find alternative eco-producers.",
      "s3_decision_5" : "I gather the company's employees affected by the supplier change to find a better solution.",
      
      "s4_decision_1" : "I prepare a training plan based on the company's training statistics and current trends.",
      "s4_decision_2" : "Over the next month, I adopt an innovative and time-intensive method to determine employees' training needs through job shadowing.",
      "s4_decision_3" : "I propose an efficient use of training resources based on the company's goals.",
      "s4_decision_4" : "I engage employees from various departments to identify their training needs.",
      "s4_decision_5" : "I plan training sessions to increase employees' awareness of sustainable thinking and how to assess the impact of their actions.",
      
      "s5_decision_1" : "I analyze my skills and assess whether the missing skills hinder my ability to work.",
      "s5_decision_2" : "I invest a lot of time to ensure my video CV is the best and highlight my ability to learn.",
      "s5_decision_3" : "I find instructions and the best examples for recording a video CV online and learn from them.",
      "s5_decision_4" : "I think of ways to better showcase my skills and achievements.",
      "s5_decision_5" : "I start improving my skills independently and add this to my CV.",
      
      "s6_decision_1" : "I ask for a raise in a meeting with my manager.",
      "s6_decision_2" : "I ask friends and acquaintances how to be successful in salary negotiations.",
      "s6_decision_3" : "I think through why the new employee's knowledge and skills are more valuable than mine.",
      "s6_decision_4" : "I discuss salary equity in a meeting with my manager to ensure equal treatment of employees.",
      "s6_decision_5" : "I demonstrate my ability to work with databases and ensure digital security.",
      
      "s7_decision_1" : "I assess the impact of the work offered on achieving sustainable development goals.",
      "s7_decision_2" : "I plan my schedule as accurately as possible until the thesis submission and stick to it.",
      "s7_decision_3" : "I discuss an appropriate thesis topic at my future workplace to combine the thesis with work.",
      "s7_decision_4" : "I familiarize myself with digital environments related to the specifics of the thesis to speed up its completion.",
      
      "s8_decision_1" : "I immediately inform the team about any problems.",
      "s8_decision_2" : "I quickly find a team member who can help.",
      "s8_decision_3" : "I look for different ways to transmit audio.",
      "s8_decision_4" : "I get acquainted with the company's digital tools before using them.",
      
      "s1" : "Choosing an internship",
      "s2" : "Difficult colleague",
      "s3" : "Responsible behavior in an advertising campaign",
      "s4" : "Understanding company needs",
      "s5" : "Job application",
      "s6" : "Salary negotiations",
      "s7" : "Working while writing a thesis",
      "s8" : "Work meeting with technical issues",
      
      "s1_text" : "Sten needs to go on an internship at the end of his university studies. He found three interesting projects on the Futulabi (University of Tartu's internship offers website) page and, after applying, received invitations from two of them. He has to decide within the next two days which one to choose. Additionally, Sten applied for a paid internship with an attractive employer and received tasks to complete within five working days.",
      "s2_text" : "Annika has an upcoming deadline to present a report she is preparing with a colleague. However, the colleague keeps apologizing for not having time and hasn't even responded to the latest emails. Annika is very frustrated with this behavior. There are only five days left until the deadline.",
      "s3_text" : "Kaarel works as a marketing specialist for a company that produces household cleaning products. For years, the company has used raw materials sourced from Finland, which have been ecologically tested and allowed the product packaging to feature the word 'environmentally friendly.' However, due to a significant rise in raw material prices, the company manager has decided to change suppliers. He now wants to use a solution produced in China that lacks eco-certificates. Despite this, the manager still wants to label the packaging and advertisements with the word 'environmentally friendly,' knowing it is important to buyers. He asks Kaarel to create a new advertising campaign that emphasizes the product's environmental friendliness.",
      "s4_text" : "Maria works in the HR department of a large media company as a newly appointed training manager. Her main task is to identify the training needs of the employees and find suitable training programs within the budget. The company employs 800 people, ranging from camera operators to hosts and technicians. Maria needs to gain an accurate understanding of the company's nature, work pace, and the training needs of its people.",
      "s5_text" : "Rasmus is about to graduate from university and is eager to start working. He has found an intriguing job advertisement and is thinking about applying. However, he hesitates because the job listing specifies seven essential skills, and Rasmus believes he only has four. He can apply with either a traditional CV or a video resume.",
      "s6_text" : "Anna has been working in her current position for three years. She enjoys her work with databases and is satisfied with everything. A few days ago, she discovered that a new employee had been hired for a similar position to hers, with a salary several hundred euros higher than hers. Anna feels that her experience makes her a more valuable employee.",
      "s7_text" : "Laura, studying environmental protection, still needs to write and defend her thesis. She has received an offer to start a full-time job in a week at her dream position in a company dealing with climate issues. Her working hours would be from 8 AM to 5 PM, which requires working in the office. Laura knows graduating from university is important, but the offered job is equally important to her.",
      "s8_text" : "Kristjan has to lead a work meeting with an international team on a new web platform he has never used before. Upon joining the meeting, he discovers that he can hear what is being said, but his microphone is not working, even though everything was fine in a Skype call just an hour ago. Kristjan realizes that the problem is not with his computer but likely with the settings of the new platform.",
      
      "s1_recommendation_1" : "Sten could gather information about the project based internship and the company and plan the next steps based on the information obtained.",
      "s1_recommendation_2" : "Sten should carefully consider the pros and cons of different options.",
      "s1_recommendation_3" : "Sten should base his choice on his personal development goals when choosing between the options.",
      "s1_recommendation_4" : "Regardless of his decision, Sten should politely respond to both offers to keep the door open for future contact.",
      
      "s2_recommendation_1" : "Annika should try to do her part of the work as well as possible, even though she is disappointed in her colleague's behavior.",
      "s2_recommendation_2" : "Annika should try to communicate empathetically with her colleague to agree on who does what and when.",
      "s2_recommendation_3" : "After giving the presentation, Annika could analyze the situation and think through the division of work with colleagues for the future.",
      "s2_recommendation_4" : "Annika could think creatively about alternatives to resolve the situation.",
      
      "s3_recommendation_1" : "Kaarel should avoid advertising a product that is harmful to the environment.",
      "s3_recommendation_2" : "Kaarel should consider the consequences of different actions in the advertising campaign and then decide how to proceed.",
      "s3_recommendation_3" : "Kaarel should conduct a critical background investigation on the solution using various sources to determine whether the lack of certification means the product is harmful to the environment.",
      "s3_recommendation_4" : "Kaarel should intervene in the process himself by finding alternative eco-producers.",
      "s3_recommendation_5" : "Kaarel could gather all employees affected by the supplier change to find the best solution.",
      
      "s4_recommendation_1" : "Maria should evaluate the current employee training participation and company developments to propose a training plan and be ready to modify it if needed.",
      "s4_recommendation_2" : "Maria could try an innovative approach, such as shadowing employees in various positions for the first month to understand their development needs.",
      "s4_recommendation_3" : "Maria should assess how to use the existing training resources, including funds, effectively based on the company's goals.",
      "s4_recommendation_4" : "Maria could involve representatives from different positions to identify employees' opinions and wishes regarding training.",
      "s4_recommendation_5" : "In addition to other topics, Maria should plan training related to a sustainable mindset to increase the awareness and capability of employees in assessing the impact of their activities.",
      
      "s5_recommendation_1" : "Rasmus should review the job description and assess whether the missing skills might prevent him from working in this position.",
      "s5_recommendation_2" : "Rasmus should stand out from other candidates by submitting a video resume highlighting his ability to learn and acquire the missing skills.",
      "s5_recommendation_3" : "Rasmus should familiarize himself with existing video CVs online to present his CV memorably.",
      "s5_recommendation_4" : "Rasmus could demonstrate how his existing strengths compensate for the missing skills and provide projects, portfolios, or other evidence as proof.",
      "s5_recommendation_5" : "Rasmus should start acquiring the missing skills independently and mention this in his resume.",
      
      "s6_recommendation_1" : "Anna should schedule a meeting with her manager in the near future to discuss her desire for a salary increase.",
      "s6_recommendation_2" : "Anna should ask friends and acquaintances how to argue in salary negotiations successfully.",
      "s6_recommendation_3" : "Anna should consider how the new employee's knowledge and skills might be more valuable to the employer than her experience.",
      "s6_recommendation_4" : "Anna should make the manager aware that equal treatment of employees is one of the values of a sustainable company.",
      "s6_recommendation_5" : "Anna should demonstrate to the manager her ability to use digital environments while ensuring digital security.",
      
      "s7_recommendation_1" : "Laura could evaluate the impact of the offered work on achieving sustainable development goals as part of her thesis.",
      "s7_recommendation_2" : "Laura could create a schedule to balance a full-time job and her thesis to decide if it is possible to combine these tasks.",
      "s7_recommendation_3" : "Laura should discuss with her future workplace what would be an appropriate thesis topic for the company to combine both activities.",
      "s7_recommendation_4" : "Laura should try to use various digital solutions as much as possible to make completing her thesis more efficient.",
      
      
      "s8_recommendation_1" : "Kristjan should immediately inform the team about any problems.",
      "s8_recommendation_2" : "Kristjan should find a team member who can help him quickly solve the problem.",
      "s8_recommendation_3" : "Kristjan could think of different unusual ways to transmit the sound to the meeting (phone app, etc.).",
      "s8_recommendation_4" : "Kristjan should test new web platforms before using them and take into account the company's security requirements.",
      
      "read_more" : "Read more",
      "read_more_alt" : "Read more on the Career Gate",
      
      "summary_title" : "Overview of Future skills",
      "summary_preblocks_text" : "Next, you will receive feedback on your self-assessment regarding future skills.",
      "summary_error_title" : "Some scenarios are unfinished!",
      "summary_error_text" : "Please go back and complete all the scenarios, then return here.",
      "summary_error_btn" : "Back to scenarios",
      "summary_graph_you" : "YOU",
      "summary_graph_others" : "OTHERS",
      "summary_open_btn" : "See results",
      
      "scenarios_heading" : "Scenarios",
      "scenarios_instruction" : "Select an unfinished scenario, review it, and complete the self-assessment based on the scenario.",
      "scenario" : "Scenario",
      "self_evalution" : "Self-assessment",
      "save" : "Save",
      "forward" : "Proceed",
      
      "recommendation_steps_title" : "Recommended steps",
      "recommendation_steps_instruction" : "To start, think about what you would do in this situation, and then review the recommendations below.",
      "recommendation_open_accordion" : "Check the recommendations",
      "recommendation_close_accordion" : "Close recommendations",
      "recommendation_forward_btn" : "Proceed to self-assessment",
      
      "userform_title" : "Background information",
      "userform_instruction" : "We use the following data for general statistics and analysis. The data are anonymous.",
      "userform_sex" : "Gender",
      "userform_male" : "Male",
      "userform_female" : "Female",
      "userform_not_specify" : "Prefer not to answer",
      "userform_agegroup_title" : "Age groups",
      "userform_upto" : "up to",
      "userform_education_title" : "Education level",
      "userform_education_bs" : "bachelor",
      "userform_education_ma" : "master",
      "userform_education_ph" : "doctoral",
      "userform_education_integrated" : "Integrated studies",
      "userform_education_vocational" : "professional higher education studies",
      "userform_education_other" : "other",
      "userform_faculty_title" : "Field",
      "userform_faculty_sv" : "Faculty of Social Sciences",
      "userform_faculty_hv" : "Faculty of Arts and Humanities",
      "userform_faculty_mv" : "Faculty of Medicine",
      "userform_faculty_lt" : "Faculty of Science and Technology",
      "userform_faculty_other" : "other",
      "userform_submit" : "Proceed to self-evaluation",
      
      "intro_modal_title" : "Hi student!",
      "intro_modal_submit" : "CONTINUE",
      
      "intro_title" : "Evaluate your skills for the future!",
      "intro_modal_text" : "Welcome to the self-assessment website <strong>for future skills</strong> at the University of Tartu. This website <strong>aims to help you better understand future skills</strong> (also known as <i>soft skills</i>, general skills, or 21st-century skills), which are essential for success in the job market and life.<br /><br />Through <strong>self-assessment</strong>, you will receive <strong>feedback</strong> on eight future skills. This feedback is not the final truth; its goal is to make you think about your strengths and development needs and to guide you in further developing yourself. Knowing your <strong>strengths</strong> and <strong>improvement</strong> areas is crucial for personal and professional growth.<br /><br />The self-assessment </span>consists of <strong>eight everyday scenarios and recommended steps</strong> to handle these situations. The scenarios address various vital topics: choosing an internship, communicating with a difficult colleague, responsible behavior, understanding company needs, applying for a job, asking for a raise, balancing study and work, and solving technical problems.<br /><br /><strong>The recommended steps</strong> help you consider different approaches to these scenarios. Then, you will move on to the self-assessment statements, evaluating how you would act in these situations. Set aside <strong>15-30 minutes</strong> for reading, reflecting, and evaluating. We are confident that this time will be well invested.<br /><br />After completing the self-assessment, you will receive the <strong>results in both graphical and textual form</strong>, providing insights into how you apply eight different future skills.<br /><br />Your responses are <strong>anonymous</strong>, and all data is processed in a way that does not allow for your identification.<br /><br />For any questions, please contact Anneli Lorenz,<br />anneli.lorenz [at]ut.ee<br />Faculty of Economics",
      "intro_introduction" : "Welcome! Here begins your journey to assess your future skills through practical scenarios.",
      "intro_li_1" : "Select a scenario and familiarize yourself with it.",
      "intro_li_2" : "Look through the suggested steps – they are guidelines, not the only solutions.",
      "intro_li_3" : "Proceed to the self-assessment, where there are 4-5 statements for each scenario.",
      "intro_li_4" : "For each statement, choose the answer that best suits you.",
      "intro_outro" : "This process will help you to understand your strengths and areas for improvement. Start your skills development journey now!",
      "intro_submit" : "Start from here!",
      
      "error_title" : "You found an error!",
      "error_text" : "Please return using the following link. If the issue persists, please get in touch with us and let us know.",
      "error_submit" : "Continue",

      "c1_url" : "en/self-management-skills",
      "c2_url" : "en/communication-skills",
      "c3_url" : "en/cooperation-skills",
      "c4_url" : "en/problem-solving",
      "c5_url" : "en/creativity",
      "c6_url" : "en/critical-thinking",
      "c7_url" : "en/digital-skills",
      "c8_url" : "en/entrepreneurial-spirit",
      "c9_url" : "en/sustainable-mindse/"

    }
  },
  et: {
    translation: {
      "o4": "Teen seda kindlasti",
      "o3": "Pigem teen seda",
      "o2": "Pigem ei tee seda",
      "o1": "Kindlasti ei tee seda",

      "c1": "Enesejuhtimisoskus",
      "c2": "Suhtlemisoskus",
      "c3": "Koostööoskus",
      "c4": "Probleemi lahendamine",
      "c5": "Loovus",
      "c6": "Kriitiline mõtlemine",
      "c7": "Digioskused",
      "c8": "Ettevõtlikkus",
      "c9": "Kestlik mõtteviis",

      "c1_feedback_bad": "Enesejuhtimisoskuste arendamine on oluline protsess, mis nõuab pidevat tähelepanu ja praktikat. Ajaplaneerimine ja prioriteetide seadmine võivad mõnikord tunduda keerulised, kuid nende oskuste arendamine on vajalik. Motivatsiooni leidmine ja stressijuhtimine on samuti olulised aspektid, mis vajavad järjepidevat harjutamist. Ülikool pakub häid võimalusi enesejuhtimisoskuste arendamiseks, pakkudes erinevaid tegevusi ja ressursse. Kasutades ära ülikooli pakutavaid võimalusi, saab igaüks oma enesejuhtimisoskusi oluliselt parandada ja tugevdada.",
      "c1_feedback_good": "Heal enesejuhtimisoskusel on märkimisväärne eelis. See hõlmab suurepärast ajaplaneerimise ja prioriteetide seadmise võimet. Isik, kes omab seda oskust, on võimeline end motiveerima ja oma emotsioone efektiivselt juhtima. Stressi haldamine on nende jaoks tavapärane tegevus, ja eneseareng on nende jaoks oluline prioriteet. Selline oskus on kasulik nii akadeemilises kui ka professionaalses arengus, aidates saavutada edu erinevates eluvaldkondades.",

      "c2_feedback_bad": "Suhtlemisoskuste arendamine on oluline, eriti kui need pole veel täielikult omandatud. Edukas suhtlemine nõuab selgust, veenvust, aktiivset kuulamist ja konfliktide vältimist. Struktureeritud argumentatsioon on samuti oluline. Nende oskuste arendamine parandab suhtlust erinevate sihtrühmadega ja aitab luua usaldusväärseid suhteid eri kultuurides. Jätkuv suhtlemisoskuste arendamine, empaatia harjutamine ja konfliktide lahendamise oskuste lihvimine avavad uusi võimalusi.",
      "c2_feedback_good": "Väljapaistvad suhtlemisoskused, sealhulgas oskuslik eneseväljendus nii suuliselt kui kirjalikult, on märkimisväärsed. Aktiivne kuulamine ja empaatia on olulised konfliktide lahendamisel ja erinevate kultuuride esindajatega suhtlemisel. Võime tõhusalt argumenteerida ja seisukohti veenvalt esitada on samuti hinnatud, eriti mitmekesistes keelelistes ja kultuurilistes kontekstides. Need oskused on kriitilise tähtsusega nii akadeemilises kui ka professionaalses arengus.",

      "c3_feedback_bad": "Koostööoskuste arendamine, eriti erinevate osapoolte kaasamisel ja empaatiavõime suurendamisel, on oluline. See pakub võimalust avastada uusi meetodeid teiste kaasamiseks ja paremaks mõistmiseks. Kuulamis- ja mõistmisvõime on olulised meeskonnatöö produktiivsuse tõstmiseks. Tiimitöö ja konfliktide lahendamise oskuste arendamine, kuigi seni võib-olla tagaplaanil, avab uusi võimalusi nende valdkondade edendamiseks. Igapäevane nende oskuste rakendamine toob kaasa kiireid edusamme meeskonnatöös.",
      "c3_feedback_good": "Väljapaistvad koostööoskused ilmnevad sageli või alati, hõlmates suurepärast kuulamisoskust ja empaatilist suhtumist teistesse, kaasates neid aktiivselt meeskonnatöösse. Tiimitöö on loomuomane, aidates kaasa meeskonna sujuvale toimimisele. Konfliktide lahendamisel on tõhusus ja kompromisside leidmise oskus olulised. Need oskused on kriitilise tähtsusega meeskonnatöös edukaks toimetulekuks ja probleemide lahendamiseks.",

      "c4_feedback_bad": "Probleemide lahendamise on väga oluline oskus, pakkudes võimalust avastada ja kasvatada analüütilist mõtlemist, loovust ja strateegilist lähenemist. Kuigi need oskused ei pruugi praegu olla esiplaanil, on potentsiaal varjatud võimete ja ideede rakendamiseks probleemide lahendamisel suur. Tähelepanu pööramine probleemide prioriteetidele ja tõhusate lahenduste leidmisele aitab märgata edusamme oskuste arendamisel. Tulemuste hindamine ja järjekindlus probleemide lahendamisel on väärtuslikud oskused, mida saab edukalt arendada ja tugevusteks muuta.",
      "c4_feedback_good": "Probleemide lahendamise oskus on muljetavaldav, hõlmates selgete probleemide määratlemist ja uuenduslike lahenduste leidmist. Võime hästi toime tulla ebakindlusega ning analüütiline, loov ja strateegiline mõtlemine on kõrgelt hinnatud. Tehtud otsused on alati hästi läbimõeldud, rõhutades rakenduste ja tulemuste hindamist ning järjekindlust probleemide lahendamisel.",

      "c5_feedback_bad": "Loovus ja uuendusmeelsus on olulised oskused, mis aitavad kaasa uudsete lahenduste leidmisel ja originaalsete ideede genereerimisel. Kuigi alternatiivsete lähenemiste otsimine ei ole esikohal, siis see peegeldab uudishimu ja leidlikkust. Loovuse puudumine probleemide lahendamisel võib takistada uuenduslikku toimetulekut väljakutsetega, piirates saavutatavate tulemuste märkimisväärsust. Seetõttu on oluline keskenduda loovuse ja uuendusmeelsuse arendamisele, et tulevikus saavutada suuremaid edusamme.",
      "c5_feedback_good": "Loovus ja uuendusmeelsus on silmapaistvad omadused, mis väljenduvad uudsete lahenduste leidmises ja originaalsete ideede esitamises. Võime otsida alternatiivseid lähenemisi peegeldab uudishimu ja leidlikkust. Loov lähenemine probleemide lahendamisele on tugevus, mis aitab uuenduslikult toime tulla väljakutsetega, tuues esile märkimisväärseid tulemusi. Jätkuv areng selles suunas on suurepärane alus tulevaste saavutuste jaoks.",

      "c6_feedback_bad": "Kriitilise mõtlemise oskus vajab tähelepanu. On oluline keskenduda süvenemisele, argumenteeritud otsuste tegemisele, küsimuste esitamisele ja erinevate vaatenurkade kaalumisele. Teadlik arendamine selles valdkonnas võimaldab avada uksi sügavamale analüüsile ja loogilistele järeldustele, rikastades igapäevast mõtlemist. Proovilpanek ja pidev areng selles suunas on oluline, et tõhustada kriitilist mõtlemist kui olulist eluaspekti.",
      "c6_feedback_good": "Kriitilise mõtlemise oskus on väga hea, ilmnedes põhjalikus olukordade analüüsis, erinevate vaatenurkade kaalutletud hindamises ja argumenteeritud, tõenditel põhinevates otsustes. Probleemide tõhus tuvastamine, küsimuste esitamine ja situatsioonide mõtestamine on oskused, mis on hästi arenenud. Loogiliste järelduste tegemine, analüütilise mõtlemise kasutamine ja olukordade kriitiline hindamine on samuti osa sellest tugevusest. Jätkuv areng ja keskendumine kriitilise mõtlemise oskuste täiustamisele on oluline edasiseks arenguks.",

      "c7_feedback_bad": "Digioskuste arendamine on praegu suurepärane võimalus isiklikuks kasvuks. Kuigi nendes valdkondades on ruumi laiendamiseks, pakub see pigem põnevat väljakutset kui takistust. Esimesed sammud digikeskkondade ja tehnoloogiate kasutamisel avavad uusi võimalusi igapäevaelus. Andmete otsimine ja nende usaldusväärsuse hindamine muutuvad järjest lihtsamaks, aidates olla infovoolus kursis. Digisisu loomise ja digiturvalisuse põhimõtete uurimine avab põneva maailma, mis rikastab oskuste pagasit. Olles avatud uutele teadmistele, saab nautida digimaailma avastamist ja oma oskusi edasi arendada.",
      "c7_feedback_good": "Digioskused on kõrgel tasemel. Tarkvara kasutatakse oskuslikult ja tehnoloogia integreerimine igapäevaellu on efektiivne. Digikeskkondade kasutamine on tõhus ning kvaliteetse digisisu loomine on oskus, millele pööratakse tähelepanu. Digiturvalisuse põhimõtete järgimine on samuti oluline. Digioskuste hindamiseks ja arendamiseks on mitmeid võimalusi, mis võimaldavad neid oskusi veelgi paremaks lihvida.",

      "c8_feedback_bad": "Ettevõtlikkuse arendamine on oluline oskus, avades uksi isiklikuks ja professionaalseks kasvuks. Proaktiivsus, kiire tegutsemine ja riskivalmidus on kesksed omadused, mis aitavad kujundada tulevikku ja muuta sind mõjukaks liidriks. Strateegiline mõtlemine, innovatsioon ja suhtlemisoskus on olulised ettevõtte jätkusuutlikkuse ja konkurentsivõime tõstmisel. Ettevõtlikkuse arendamine võimaldab sul teha märkimisväärseid edusamme ja nautida oma teekonda.",
      "c8_feedback_good": "Ettevõtlikkuse oskused on väga head, ilmutades proaktiivsust ja valmidust kiireks tegutsemiseks ettevõtte strateegilises suunamises. Kestliku mõtteviisi edendamine ettevõttes ning uute tarnijate ja partnerite leidmine on samuti tugevad küljed. Loominguline mõtlemine ja innovaatilisus võimaldavad leida alternatiivseid lahendusi, edendades tootearendust ja teenuste pakkumist. Suhtlemisoskus nii meeskonnasiseselt kui ka välispartneritega on suurepärane, aidates lahendada ettevõtlusega seotud probleeme tõhusalt.",

      "c9_feedback_bad": "Kestlik mõtteviis on oluline oskus, mis aitab kujundada maailma ning hinnata oma tegevuse mõju. Praegu võivad keskkonnateemad tunduda kõrvalised, kuid on suurepärane võimalus selles valdkonnas areneda. Kestliku mõtteviisi mõju ulatub kaugemale igapäevaelust, pakkudes rikastavaid kogemusi nii isiklikus elus kui ka akadeemilises ja professionaalses arengus. Selle oskuse arendamine avab uusi uksi ja võimalusi, nii ülikoolis kui ka tulevases karjääris. Olles avatud uutele perspektiividele, on võimalus astuda kindlaid samme jätkusuutlikuma tuleviku poole.",
      "c9_feedback_good": "Kestliku mõtteviisi oskus on üha enam hinnatud. See hõlmab keskkonnasõbralike toodete ja lahenduste propageerimist ning aktiivset osalemist jätkusuutlikkuse strateegiate väljatöötamisel ja rakendamisel. Kestlik mõtteviis on kultuuri ja väärtuste lahutamatu osa. Ettevõtted analüüsivad klientide ja turu vaateid jätkusuutlikkuse aspektist, tõstavad teadlikkust keskkonna- ja jätkusuutlikkuse teemadel ning võtavad sageli vabatahtlikult juhtrolli ühiskondlikus ja keskkonnaalases vastutuses.",

      "c1_tag1": 'eneseregulatsioon',
      "c1_tag2": 'kohanemisvõime',
      "c1_tag3": 'õpioskused',
      "c2_tag1": 'eneseväljendus',
      "c2_tag2": 'aktiivne kuulamine',
      "c2_tag3": 'kultuuridevaheline suhtlemine',
      "c3_tag1": 'kaasamine',
      "c3_tag2": 'empaatilisus',
      "c3_tag3": 'juhtimis- ja läbirääkimisoskused',
      "c4_tag1": 'probleemi tuvastamine',
      "c4_tag2": 'lahenduskesksus',
      "c4_tag3": 'toimetulek määramatusega',
      "c5_tag1": 'uudishimu',
      "c5_tag2": 'uuendusmeelsus',
      "c5_tag3": 'leidlikkus',
      "c6_tag1": 'analüütiline mõtlemine',
      "c6_tag2": 'küsimuste esitamine',
      "c6_tag3": 'mõtestamine',
      "c7_tag1": 'digikeskkondade kasutamine',
      "c7_tag2": 'digisisu loomine',
      "c7_tag3": 'digiturvalisus',
      "c8_tag1": 'tegutsemisvalmidus',
      "c8_tag2": 'riskimisjulgus',
      "c8_tag3": 'proaktiivsus',
      "c9_tag1": 'kestliku arengu olemuse mõistmine',
      "c9_tag2": 'oma tegevuse mõju hindamine',
      "c9_tag3": 'suunatus tulevikku',


      "s1_decision_1": "Kogun infot ja olen orienteeritud lahenduse leidmisele.",
      "s1_decision_2": "Kaalun hoolsalt ja mõtestan eri võimaluste plusse ja miinuseid.",
      "s1_decision_3": "Võtan aega ja leian variandi, millest mul on kõige rohkem õppida.",
      "s1_decision_4": "Sõltumata valitud lahendusest püüan kõigile osapooltele vastata meeldejäävalt ja asjatundlikult.",

      "s2_decision_1": "Vajaduse korral leian endas motivatsiooni ja teen kogu ettekande ise ära.",
      "s2_decision_2": "Suhtlen kolleegiga empaatiliselt, et probleemi tuumani jõuda ning mõtlen temaga koos välja, kuidas ettekanne ühiselt ära teha.",
      "s2_decision_3": "Analüüsin pärast ettekande tegemist, miks selline olukord tekkis ja mida oleksin mina saanud teisiti teha.",
      "s2_decision_4": "Pakun ise välja erinevaid võimalusi olukorra lahendamiseks.",

      "s3_decision_1": "Välistan keskkonnakahjuliku toote reklaamimise, sest mõistan sellise tegevuse mõju keskkonnale.",
      "s3_decision_2": "Mõtlen hoolikalt läbi eri lahendusviiside võimalikud tagajärjed ning koostan sellele toetudes sobiva plaani.",
      "s3_decision_3": "Kogun asjakohast infot, et toote kahjulikkust keskkonnale analüüsida ja kaalukate põhjendusteni jõuda.",
      "s3_decision_4": "Tegutsen ennetavalt (ilma et firma juht seda paluks), et leida alternatiivseid ökotootjaid.",
      "s3_decision_5": "Kutsun kokku ettevõtte töötajad, keda puudutab tarnija vahetus, et leida parem lahendus.",

      "s4_decision_1": "Koostan koolitusplaani, toetudes ettevõtte koolitusstatistikale ning toimuvaid arenguid silmas pidades.",
      "s4_decision_2": "Võtan järgmise kuu jooksul ette üsna uudse ja ajamahuka viisi töötajate koolitusvajaduse väljaselgitamiseks (töötajate töövari).",
      "s4_decision_3": "Pakun ettevõtte eesmärkidest lähtuvalt koolitusvahendite efektiivse kasutamise plaani.",
      "s4_decision_4": "Kaasan ettevõtte erinevate ametite töötajaid koolitussoovide väljaselgitamiseks.",
      "s4_decision_5": "Kavandan töötajatele koolitusi, et tõsta nende teadlikkust kestlikkust mõtteviisist ning oma tegevuse mõju hindamisest.",

      "s5_decision_1": "Analüüsin oma oskusi ja hindan, kas puuduvad oskused takistavad töötamist.",
      "s5_decision_2": "Panustan rohkelt aega, et minu video-CV oleks parim ning toon oma õppimisvõimet seal ka esile.",
      "s5_decision_3": "Leian veebist video-CV salvestamise juhised ja parimad näited ning õpin nende põhjal seda looma.",
      "s5_decision_4": "Mõtlen välja, mil viisil veel paremini saan oma oskusi ja saavutusi tutvustada.",
      "s5_decision_5": "Asun täiendama oma oskusi iseseisvalt ning lisan selle CV-sse.",

      "s6_decision_1": "Küsin kohtumisel juhiga palka juurde.",
      "s6_decision_2": "Uurin sõpradelt ja tuttavatelt, kuidas palgaläbirääkimistel edukas olla.",
      "s6_decision_3": "Mõtlen läbi, et miks uue töötaja teadmised ja oskused on väärtuslikumad kui minu omad.",
      "s6_decision_4": "Räägin kohtumisel juhiga palgaõigluse teemal, et tagada töötajate võrdne kohtlemine.",
      "s6_decision_5": "Näitan oskust töötada andmebaasidega ning oskust tagada digiturvalisus.",

      "s7_decision_1": "Hindan pakutava töö mõju kestliku arengu eesmärkide saavutamisele.",
      "s7_decision_2": "Planeerin oma ajakava kuni lõputöö esitamiseni võimalikult täpselt ja pean sellest ka kinni.",
      "s7_decision_3": "Arutan tulevases töökohas sobiva lõputöö ülesandepüstituse üle, et lõputööd ja töötamist ühendada.",
      "s7_decision_4": "Tutvun lõputöö spetsiifikaga seotud digikeskkondadega, et kiirendada töö valmimist.",

      "s8_decision_1": "Teavitan kohe meeskonda probleemidest.",
      "s8_decision_2": "Leian kiiresti meeskonnakaaslase, kes aitaks.",
      "s8_decision_3": "Otsin erinevaid võimalusi heli edastamiseks.",
      "s8_decision_4": "Tutvun enne kasutamist ettevõtte digikeskkondadega.",

      "s1": "Praktikakoha valimine",
      "s2": "Raske kolleeg",
      "s3": "Vastutustundlik käitumine reklaamikampaanias",
      "s4": "Ettevõtte vajaduste mõistmine",
      "s5": "Tööle kandideerimine",
      "s6": "Palgaläbirääkimised",
      "s7": "Tööle asumine lõputöö kirjutamise ajal",
      "s8": "Tehnoapsudega töökoosolek",

      "s1_text": "Sten peab ülikooliõpingute lõpus minema praktikale. Ta leidis Futulabi (TÜ praktikapakkumiste veeb) lehelt kolm huvipakkuvat projekti ja pärast kandideerimist sai kutse neist kahte. Ta peab kahe järgmise päeva jooksul otsustama, kumma ta valib. Lisaks kandideeris Sten tasustatud praktikale atraktiivse tööandja juurde ja sai ülesanded, mille lahendused tuleb esitada viie tööpäeva jooksul.",
      "s2_text": "Annikal on lähenemas koos kolleegiga koostatava ettekande esitamise tähtaeg. Kolleeg aga üksnes vabandab, et tal ei ole aega. Viimastele e-kirjadele ei ole ta isegi vastanud. Annika on sellise käitumise peale väga nördinud. Tähtajani on jäänud ainult viis päeva.",
      "s3_text": "Kaarel töötab turundusspetsialistina ettevõttes, mis toodab puhastusvahendeid olmekasutuseks. Ettevõte on aastaid kasutanud toorainena Soomes toodetud lahuseid, mida on ökoloogiliselt testitud - tänu sellele on toote pakendil sõna „keskkonnasõbralik“. Nüüd on toormehinnad märgatavalt tõusnud ja firmajuht otsustanud tarnijat vahetada. Ta soovib hakata kasutama Hiinas toodetud lahust, millel ökosertifikaadid puuduvad. Ometi tahab juht ka edaspidi kasutada pakendil ja reklaamis sõna „keskkonnasõbralik“, sest ta teab, et see on ostjate jaoks oluline. Ta palub Kaarlil teha uue reklaamikampaania, kus rõhutatakse toote keskkonnasõbralikkust.",
      "s4_text": "Maria töötab suure meediaettevõtte personaliosakonnas värske koolitusjuhina. Tema peamine ülesanne on teha kindlaks inimeste koolitusvajadused ja leida sobivad koolitused, arvestades eelarvet. Ettevõttes töötab 800 inimest alates teleoperaatoritest kuni saatejuhtide ja tehnikuteni. Marial on vaja saada võimalikult adekvaatne ülevaade ettevõtte olemusest, töötempost ja inimeste koolitusvajadustest.",
      "s5_text": "Rasmus lõpetab lähiajal ülikooli ja tahab minna tööle. Ta on leidnud ühe huvipakkuva töökuulutuse ja kaalub kandideerimist. Teda paneb kõhklema asjaolu, et tööpakkuja on kuulutuses nimetanud seitset tööks vajalikku oskust, millest Rasmusel on enda arvates olemas vaid neli. Kandideerida on võimalik nii klassikalise kui ka video-CV alusel.",
      "s6_text": "Anna on praegusel ametikohal töötanud kolm aastat. Andmebaasidega seotud töö meeldib talle ja ta on kõigega rahul. Paar päeva tagasi sai ta teada, et tema tööga samaväärsele ametikohale võeti uus töötaja, kelle töötasu on mitusada eurot suurem kui temal. Anna tunneb, et tema on oma kogemuste tõttu väärtuslikum töötaja.",
      "s7_text": "Keskkonnakaitse erialal õppival Laural on veel jäänud kirjutada ja kaitsta lõputöö, kui talle tehakse ettepanek alustada nädala pärast täiskoormusega tööd tema unistuste ametis kliimaprobleemidega tegelevas ettevõttes. Tema tööaeg oleks kell 8-17 ja töötada tuleb kontoris. Laura teab, et ülikooli lõpetamine on oluline, aga pakutav töökoht on talle sama tähtis.",
      "s8_text": "Kristjan peab juhtima rahvusvahelise meeskonnaga töökoosolekut uuel veebiplatvormil, mida ta pole varem kasutanud. Koosolekuga liitumisel avastab ta, et kuuleb, mida räägitakse, aga tema mikrofon ei tööta, ehkki alles tund aega tagasi peetud Skype’i kõnes oli kõik korras. Kristjan saab aru, et probleem pole tema arvutis, vaid tõenäoliselt uue platvormi sätetes.",

      "s1_recommendation_1": "Sten võiks koguda teavet nii projektipraktika kui ettevõtte kohta ning kavandada järgnevad sammud saadud infost lähtuvalt.",
      "s1_recommendation_2": "Sten peaks hoolikalt läbi mõtlema erinevate võimaluste plussid ja miinused.",
      "s1_recommendation_3":  "Sten peaks lähtuma enesearengu eesmärkidest eri võimaluste vahel valides.",
      "s1_recommendation_4": "Hoolimata oma otsusest peaks Sten viisakalt vastama mõlemale pakkumisele, et jätta endale võimalus edaspidi ise ühendust võtta.",

      "s2_recommendation_1": "Annika peaks püüdma oma osa tööst ära teha võimalikult hästi, kuigi on pettunud kolleegi käitumises.",
      "s2_recommendation_2": "Annika peaks püüdma kolleegiga empaatiliselt suhelda, et kokku leppida, kes, mida ja millal teeb.",
      "s2_recommendation_3":  "Annika võiks pärast ettekande tegemist analüüsida tekkinud olukorda ning tulevikuks mõelda läbi tööjaotus kolleegidega.",
      "s2_recommendation_4": "Annika võiks loovalt mõelda, milliseid alternatiive olukorra lahendamiseks veel on.",

      "s3_recommendation_1": "Kaarel peaks välistama keskkonnakahjuliku toote reklaamimise.",
      "s3_recommendation_2": "Kaarel peaks kaaluma reklaamikampaanias eri tegutsemisviiside tagajärgi ja langetama seejärel otsuse, kuidas käituda.",
      "s3_recommendation_3": "Kaarel peaks eri allikaid kasutades tegema lahuse kohta kriitilise taustauuringu, et selgitada välja, kas sertifikaadi puudumine tähendab toote kahjulikkust keskkonnale või mitte.",
      "s3_recommendation_4": "Kaarel peaks sekkuma ise protsessi käiku, leides alternatiivseid ökotootjaid.",
      "s3_recommendation_5": "Kaarel võiks kokku kutsuda kõik töötajad, keda puudutab tarnija vahetus, et leida parim lahendus.",

      "s4_recommendation_1": "Maria peaks senist töötajate koolitustel osalemist ja ettevõttes toimuvaid arenguid hinnates välja pakkuma koolitusplaani ja olema valmis seda vajadusel muutma.",
      "s4_recommendation_2": "Maria võiks proovida uudset lähenemisviisi ja näiteks veeta esimese kuu eri ametikohtadel töötavate inimeste töövarjuna, et mõista nende arenguvajadusi.",
      "s4_recommendation_3": "Maria peaks ettevõtte eesmärkidest lähtuvalt hindama, kuidas olemasolevat koolitusressurssi, sh raha, efektiivselt kasutada.",
      "s4_recommendation_4": "Maria võiks kaasata eri ametite esindajaid, et selgitada välja töötajate arvamused ja soovid koolituste osas.",
      "s4_recommendation_5": "Lisaks muudele teemadele peaks Maria kavandama kestliku mõtteviisiga seotud koolitusi, et tõsta ettevõtte töötajate teadlikkust ja võimekust oma tegevuse mõju hindamisel.",

      "s5_recommendation_1": "Rasmus peaks uurima töö kirjeldust ja hindama, kas puuduvad oskused võivad takistada tal sellel töökohal töötamist.",
      "s5_recommendation_2": "Rasmus peaks teiste kandidaatide seast silma paistma, esitades video-CV, milles rõhutab oma õppimisvõimet puuduvate oskuste omandamisel.",
      "s5_recommendation_3": "Rasmus peaks tutvuma veebis olemasolevate video-CV-dega, et esitada oma CV meeldejääval viisil.",
      "s5_recommendation_4": "Rasmus võiks näidata, millised tema olemasolevad tugevused asendavad puuduvaid oskusi ning esitama nende  tõenduseks projekte, portfoolio vms.",
      "s5_recommendation_5": "Rasmus peaks puuduvaid oskusi iseseisvalt omandama asuma ning sellest ka CVs teada andma.",

      "s6_recommendation_1": "Anna peaks lähemal ajal kokku leppima kohtumise juhiga, et rääkida oma soovist töötasu tõstmise osas.",
      "s6_recommendation_2": "Anna peaks uurima sõpradelt ja tuttavatelt, kuidas palgaläbirääkimistel edukalt argumenteerida.",
      "s6_recommendation_3": "Anna peaks läbi mõtlema, mille poolest uue töötaja teadmised ja oskused võivad tööandja jaoks olla rohkem väärt kui tema kogemus.",
      "s6_recommendation_4": "Anna peaks juhile teadvustama, et töötajate võrdne kohtlemine on jätkusuutliku ettevõte üks väärtustest.",
      "s6_recommendation_5": "Anna peaks juhile demostreerima oma oskust kasutada digikeskkondi tagades digiturvalisuse.",

      "s7_recommendation_1": "Laura saaks lõputöö raames hinnata pakutava töö mõju kestliku arengu eesmärkide saavutamisele.",
      "s7_recommendation_2": "Laura võiks koostada ajakava täiskoormusega töö ja lõputöö sobitamiseks, et otsustada, kas neid ülesandeid on võimalik ühitada.",
      "s7_recommendation_3": "Laura peaks arutama oma tulevases töökohas, milline võiks olla ettevõttele sobiv lõputöö ülesandepüstitus, et kahte tegevust ühendada.",
      "s7_recommendation_4": "Laura peaks püüdma võimalikult palju kasutada eri digilahendusi, et lõputöö valmimist efektiivistada.",


      "s8_recommendation_1": "Kristjan peaks probleemidest meeskonda kohe teavitama.",
      "s8_recommendation_2": "Kristjan peaks leidma meeskonnakaaslase, kes aitaks tal kiiresti probleemi lahendada.",
      "s8_recommendation_3": "Kristjan võiks mõelda erinevatele ebatavalistele võimalustele, kuidas heli koosolekule edastada (telefonirakendus vms lahendus).",
      "s8_recommendation_4": "Kristjan peaks edaspidi enne uute veebiplatvormide kasutamist neid katsetama ning arvestama ettevõtte turvanõuetega.",

      "read_more": "Loe lisaks",
      "read_more_alt": "Loe lähemalt Karjääriväravast",

      "summary_title": "Tulevikuoskuste ülevaade",
      "summary_preblocks_text": "Edasi järgneb Sinu enesehinnangu tagasiside tulevikuoskuste lõikes",
      "summary_error_title": "Mõni stsenaarium on jäänud lõpetamata!",
      "summary_error_text": "Palun mine tagasi ja täida kõik stsenaariumid ja pöördu seejärel siia tagasi.",
      "summary_error_btn": "Tagasi stsenaariumite lehele",
      "summary_graph_you": "SINA",
      "summary_graph_others": "TEISED",
      "summary_open_btn": "Vaata tulemusi",

      "scenarios_heading": "Stsenaariumid",
      "scenarios_instruction": "Vali lõpetamata stsenaarium, tutvu sellega ning täida stsenaariumi alusel enesehinnang.",
      "scenario": "Stsenaarium",
      "self_evalution": "Enesehindamine",
      "save": "Salvestan",
      "forward": "Edasi",

      "recommendation_steps_title": "Soovituslikud sammud",
      "recommendation_steps_instruction": "Alustuseks mõtle, mida sina selleks olukorras teeksid, ja seejärel tutvu alltoodud soovitustega.",
      "recommendation_open_accordion": 'Tutvu soovitustega',
      "recommendation_close_accordion": 'Sulge soovitused',
      "recommendation_forward_btn": "Edasi enesehindamisele",

      "userform_title": "Taustaandmed",
      "userform_instruction": "Kasutame järgnevaid andmeid üldise statistika ja analüüsi jaoks. Andmed on anonüümsed.",
      "userform_sex": "Sugu",
      "userform_male": "Mees",
      "userform_female": "Naine",
      "userform_not_specify": "Ei soovi vastata",
      "userform_agegroup_title": "Vanusegrupid",
      "userform_upto": "kuni",
      "userform_education_title": "Omandatav haridustase",
      "userform_education_bs": "bakalaureus",
      "userform_education_ma": "magister",
      "userform_education_ph": "doktor",
      "userform_education_integrated": "integreeritud õpe",
      "userform_education_vocational": "rakenduskõrgharidus",
      "userform_education_other": "muu",
      "userform_faculty_title": "Valdkond",
      "userform_faculty_sv": "Sotsiaalteaduste valdkond",
      "userform_faculty_hv": "Humanitaarteaduste ja kunstide valdkond",
      "userform_faculty_mv": "Meditsiiniteaduste valdkond",
      "userform_faculty_lt": "Loodus- ja täppisteaduste valdkond",
      "userform_faculty_other": "muu",
      "userform_submit": "Edasi enesehindamise juurde",

      "intro_modal_title": "Hei üliõpilane!",
      "intro_modal_submit": "JÄTKA",
      "intro_modal_text": "Tere tulemast Tartu Ülikooli <strong>tulevikuoskuste enesehindamise</strong> veebilehele. Veebilehe <strong>eesmärk on aidata Sul paremini mõista tulevikuoskusi</strong> (tuntakse ka kui <i>Soft Skills</i>, üldoskused, 21. sajandi oskused), mis aitavad tööturul ja ka elus edukam olla.<br /><br />Läbi <strong>enesehindamise</strong> saad <strong>tagasiside</strong> kaheksale tulevikuoskusele. Tagasiside, mille saad, ei ole lõplik tõde, vaid eesmärk on panna Sind mõtlema oma tugevustest ja arenguvajadustest ning suunama ennast edasi arendama. Teadmine oma <strong>tugevustest</strong> ja <strong>arenguvajadustest</strong> on oluline nii isiklikus kui ka tööalases arengus.<br /><br />Enesehindamine koosneb <strong>kaheksast igapäevaelul põhinevast stsenaariumist</strong> ja soovituslikest sammudest nende olukordade lahendamiseks. Stsenaariumid puudutavad erinevaid olulisi teemasid: praktikakoha valimist, suhtlemist keerulise kolleegiga, vastutustundlikku käitumist, ettevõtte vajaduste mõistmist, tööle kandideerimist, palga küsimist, samaaegset õppimist ja töötamist ning tehniliste probleemide lahendamist.<br /><br /><strong>Soovituslikud sammud</strong> aitavad Sul kaaluda erinevaid lahenduskäike. Seejärel liigud enesehindamise väidete juurde, kus hindad, kuidas ise sellistes olukordades käituksid. Lugemiseks, mõtlemiseks ja hindamiseks varu aega umbes <strong>15-30 minutit</strong>. Oleme kindlad, et see aeg on hästi investeeritud.<br /><br />Pärast enesehindamise läbimist saad tulemused <strong>graafilisel ja teksti kujul</strong>, mis annavad sisendi, et kuidas Sina kaheksat erinevat tulevikuoskust rakendad ning kus oled võrreldes teistega.<br /><br /><strong>Sinu vastused on anonüümsed</strong> ja kõiki andmeid töödeldakse viisil, mis ei võimalda Sinu isikut tuvastada.<br /><br />Küsimuste korral võta ühendust:<br />Anneli Lorenz, anneli.lorenz[at]ut.ee<br />Majandusteaduskond",

      "intro_title": "Hinda enda tulevikuoskusi!",
      "intro_introduction": "Tere tulemast! Siit algab sinu teekond tulevikuoskuste hindamiseks läbi praktiliste stsenaariumide.",
      "intro_li_1": "Vali stsenaarium ja tutvu sellega.",
      "intro_li_2": "Vaata läbi soovitatud sammud – need on suunised, mitte ainukesed lahendused.",
      "intro_li_3": "Mine enesehindamise juurde, kus iga stsenaariumi kohta on 4-5 väidet.",
      "intro_li_4": "Vali iga väite kohta endale kõige sobivam vastus.",
      "intro_outro": "See protsess aitab sul mõista oma tugevusi ja arengukohti. Alusta nüüd oma oskuste arendamise teekonda!",
      "intro_submit": "Alusta siit!",

      "error_title": "Leidsid vea!",
      "error_text": "Palun pöördu tagasi kasutades järgnevat linki. Vea korduval mittelahenemisel palun võta meiega ühendust ja anna meile teada.",
      "error_submit": "Jätka",

      "c1_url" : "enesejuhtimine",
      "c2_url" : "suhtlemisoskus",
      "c3_url" : "koostoooskus",
      "c4_url" : "probleemi-lahendamine",
      "c5_url" : "loovus",
      "c6_url" : "kriitiline-motlemine",
      "c7_url" : "digioskused",
      "c8_url" : "ettevotlikkus",
      "c9_url" : "kestlik-motteviis"

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'], // don't convert to <1></1> if simple react elements


    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;