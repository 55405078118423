export const allFutureSkills = [
  {
    skill:"c1",
    link: "c1_url",
    connected: [
      "s1_3","s2_1","s6_3","s7_2"
    ],
    ids: [
      2, 4, 25, 29
    ],
    correlated_ids: [
      2, 25
    ],
    feedback: [
     "c1_feedback_bad",
     "c1_feedback_good"
    ],
    tags: [
     "c1_tag1",
     "c1_tag2",
     "c1_tag3"
    ]
  },
  {
    skill:"c2",
    link: "c2_url",
    connected: [
      "s1_4","s2_2","s6_2","s8_1"
    ],
    ids: [
      3, 5, 24, 32
    ],
    correlated_ids: [
      5, 32
    ],
    feedback: [
     "c2_feedback_bad",
     "c2_feedback_good"
    ],
    tags: [
     "c2_tag1",
     "c2_tag2",
     "c2_tag3"
    ]
  },
  {
    skill:"c3",
    link: "c3_url",
    connected: [
      "s3_5","s4_4","s7_3","s8_2"
    ],
    ids: [
      12, 16, 30, 33
    ],
    correlated_ids: [
      16, 30
    ],
    feedback: [
     "c3_feedback_bad",
     "c3_feedback_good"
    ],
    tags: [
     "c3_tag1",
     "c3_tag2",
     "c3_tag3"
    ]
  },
  {
    skill:"c4",
    link: "c4_url",
    connected: [
      "s1_1","s3_2","s4_1","s5_2"
    ],
    ids: [
      0, 9, 13, 19
    ],
    correlated_ids: [
      13, 19
    ],
    feedback: [
     "c4_feedback_bad",
     "c4_feedback_good"
    ],
    tags: [
     "c4_tag1",
     "c4_tag2",
     "c4_tag3"
    ]
  },
  {
    skill:"c5",
    link: "c5_url",
    connected: [
      "s2_4","s4_2","s5_4","s8_3"
    ],
    ids: [
      7, 14, 21, 34
    ],
    correlated_ids: [
      7, 21
    ],
    feedback: [
     "c5_feedback_bad",
     "c5_feedback_good"
    ],
    tags: [
     "c5_tag1",
     "c5_tag2",
     "c5_tag3"
    ]
  },
  {
    skill:"c6",
    link: "c6_url",
    connected: [
      "s1_2","s2_3","s3_3","s5_1"
    ],
    ids: [
      1, 6, 10, 18
    ],
    correlated_ids: [
      1, 18
    ],
    feedback: [
     "c6_feedback_bad",
     "c6_feedback_good"
    ],
    tags: [
     "c6_tag1",
     "c6_tag2",
     "c6_tag3"
    ]
  },
  {
    skill:"c7",
    link: "c7_url",
    connected: [
      "s5_3","s6_5","s7_4","s8_4"
    ],
    ids: [
      20, 27, 31, 35
    ],
    correlated_ids: [
      20, 31
    ],
    feedback: [
     "c7_feedback_bad",
     "c7_feedback_good"
    ],
    tags: [
     "c7_tag1",
     "c7_tag2",
     "c7_tag3"
    ]
  },
  {
    skill:"c8",
    link: "c8_url",
    connected: [
      "s3_4","s4_3","s5_5","s6_1",
    ],
    ids: [
      11, 15, 22, 23
    ],
    correlated_ids: [
      11, 22
    ],
    feedback: [
     "c8_feedback_bad",
     "c8_feedback_good"
    ],
    tags: [
     "c8_tag1",
     "c8_tag2",
     "c8_tag3"
    ]
  },
  {
    skill:"c9",
    link: "c9_url",
    connected: [
      "s3_1","s4_5","s6_4","s7_1"
    ],
    ids: [
      8, 17, 26, 28
    ],
    correlated_ids: [
      17, 26
    ],
    feedback: [
     "c9_feedback_bad",
     "c9_feedback_good"
    ],
    tags: [
     "c9_tag1",
     "c9_tag2",
     "c9_tag3"
    ]
  },
];
