import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../useLocalStorage";
import i18n from 'i18next';

export default function Finish() {

  const userData = JSON.parse(useLocalStorage("userdata")[0]);
  const scenarioData = JSON.parse(useLocalStorage("scenario")[0]);
  const navigateToResults = useNavigate();



  async function sendCollectedData(event) {
    event.preventDefault();
    let completeUserData = [...userData, ...scenarioData];

    const formData = new FormData();
    formData.append("scenariodata", completeUserData);

    try {
      // TODO: later remove localhost and just leave actions.php
      const response = await fetch("actions.php", {
        method: "POST",
        // Set the FormData instance as the request body
        body: formData,
      });
      console.log(await response.json());
    } catch (e) {
      console.error(e);
    }

    navigateToResults("/summary");
  }

  return (
    <>
    <form onSubmit={sendCollectedData} className="m-auto w-full text-center">
      <button type="submit" className="btn btn-success text-white mb-10">{i18n.t("summary_open_btn")}</button>
    </form>
    </>
  );


}