//import ReactECharts from 'echarts-for-react';
import { useLocalStorage } from '../../useLocalStorage';
import { allFutureSkills } from '../../utils/allScenariosFutureSkills';
// eslint-disable-next-line
import i18n from 'i18next';
import { useTranslation } from "react-i18next";


import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { RadarChart } from 'echarts/charts';

import {
  // GridSimpleComponent,
  // GridComponent,
  // PolarComponent,
   RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
   GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  // TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  LegendComponent,
  LegendScrollComponent,
  LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  TransformComponent,
  DatasetComponent,
} from 'echarts/components';

import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';

// Register the required components
echarts.use(
  [RadarComponent, RadarChart, TransformComponent,DatasetComponent, GraphicComponent,  CanvasRenderer, LegendComponent, LegendScrollComponent, LegendPlainComponent, TooltipComponent]
);

const Graph = () => {

  const results =  useLocalStorage('scenario');
  let scenerioDataArr = [];
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  if (localStorage.getItem("scenario") !== undefined) {
    scenerioDataArr = JSON.parse(results[0]);
  }

  function createAverages(arr) {
    let averages = [];

    allFutureSkills.forEach((futureSkill) => {
      let bucket = 0;
      let counter = 0;
      futureSkill.correlated_ids.forEach((e) => {
        bucket += scenerioDataArr[e]
        counter++;
      });

      averages.push(Math.round(bucket/counter * 100)/100);
    })
    return averages;
  }

  const onChartClick = (params) => {
    console.log("Chart clicked", params);
  }

  const onEvents = {
    click: onChartClick,
  }

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}<br/>'
    },
    legend: {
      data: [i18n.t("summary_graph_others"), i18n.t("summary_graph_you")],
      bottom: 0,
      itemGap: 20,
      textStyle: {
        color: '#333',
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    radar: {
      nameGap: 30,
      shape: 'polygon',
      indicator: [
          { name: i18n.t("c1").replace(" ","\n").replace("-","-\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c2").replace(" ","\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c3").replace(" ","\n").replace("öö","öö-\n").replace("lla","lla-\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c4").replace(" ","\n").replace("-","-\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c5").toUpperCase(), max: 4.5},
          { name: i18n.t("c6").replace(" ","\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c7").replace(" ","\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c8").replace(" ","\n").replace("tte","tte-\n").replace("pre","pre-\n").toUpperCase(), max: 4.5},
          { name: i18n.t("c9").replace(" ","\n").toUpperCase(), max: 4.5}
      ],
      name: {
        triggerEvent: true
      },

      axisName: {
        color: '#2c5697',
        fontSize: 14,
        formatter: '{value}',
        backgroundColor: '#fff',
        borderColor: '#f5f5f5',
        fontFamily: 'Rubik',
        fontWeight: '600',
        borderWidth: 1,
        borderRadius: 2,
        padding: [8, 8],
        align: 'center',
        triggerEvent: true,
      },
      splitLine: {
        lineStyle: {
          color: [
            'rgba(238, 197, 102, 0.1)',
            'rgba(238, 197, 102, 0.2)',
            'rgba(238, 197, 102, 0.4)',
            'rgba(238, 197, 102, 0.6)',
            'rgba(238, 197, 102, 0.8)',
            'rgba(238, 197, 102, 1)'
          ].reverse()
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: [
              '#FC858940',
              '#fba0a340',
              '#f8c88340',
              '#9dba8c40',
              '#67af6040',
            ]
          }
      },
    },
    series: [{
      selectedMode: 'single',

      type: 'radar',

      emphasis: {
        focus: 'self'
      },

      labelLayout: function() {
        return {
          moveOvelap: 'shiftY'
        }
      },

      data : [
        {
          value : [3.32, 3.51, 3.56, 3.27, 3.43, 3.67, 3.25, 2.85, 3.15],
          name : i18n.t("summary_graph_others"),
          symbol: 'rect',
          lineStyle: {
            type: 'dashed'
          },
        },
        {
          value: createAverages(scenerioDataArr),
          name : i18n.t("summary_graph_you"),
         areaStyle: {
            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
              {
                color: 'rgba(46, 125, 50, 0.1)',
                offset: 0
              },
              {
                color: 'rgba(46, 125, 50, 0.9)',
                offset: 1
              }
            ])
          }
        },

      ]
    }]
  };

  return (
    <>
     <div id="overviewChart">
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          style={{ height: '70vh', width: '98%', margin: 'auto', padding: '10px' }}
          onEvents={onEvents}
        />
      </div>
      <div id="printChart">
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          style={{ height: '800px', width: '800px', margin: 'auto', padding: '10px' }}
          onEvents={onEvents}
        />
      </div>
    </>
    );
};

export default Graph;