/* Taustaandmed:
    Sugu (mees, naine, ei vasta)
    Valdkond (neli valdkonda);
    vanusegrupid (kuni 20, 21-25; 26-30’; ja 30+ jne),
    tase – baka, mag, dokt, integreeritud õpe, rakenduskõrgharidus
    */

import { useLocalStorage } from "../../useLocalStorage";
import { Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from "react-router-dom";
import i18n from 'i18next';


export default function UserForm( {isOpen, setIsOpen} ) {

  /* Ignore the ".. but never used" Warning */
  // eslint-disable-next-line
  const [userData, setUserData] = useLocalStorage('userdata',  '');
  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
    navigate("/recommendation/1");
  }

  function collectData(event) {
    event.preventDefault();

    const userDataArr = [
      Number(event.target.gender.value),
      Number(event.target.agegroup.value),
      Number(event.target.education.value),
      Number(event.target.faculty.value)
    ];

    setUserData(JSON.stringify(userDataArr));

  }

  return (
    <>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center select-none">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-none bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 !text-accent uppercase"
                  >
                    {i18n.t("userform_title")}
                  </Dialog.Title>
                  <div className="mt-2">
                  </div>

                  <form id="userData" onSubmit={collectData}>
                    <div className="space-y-12">
                      <div className="border-b border-gray-900/10 pb-6">
                        {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Taustandmed</h2> */}
                        <p className="mt-1 text-sm leading-6 text-gray-600">{i18n.t("userform_instruction")}</p>

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">

                          <div className="sm:col-span-4">
                            <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                            {i18n.t("userform_sex")}
                            </label>
                            <div className="mt-2">
                              <select
                                id="gender"
                                name="gender"
                                autoComplete="gender-name"
                                className="block w-full rounded-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neutral sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option value="1">{i18n.t("userform_male")}</option>
                                <option value="2">{i18n.t("userform_female")}</option>
                                <option value="3">{i18n.t("userform_not_specify")}</option>
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="agegroup" className="block text-sm font-medium leading-6 text-gray-900">
                            {i18n.t("userform_agegroup_title")}
                            </label>
                            <div className="mt-2">
                              <select
                                id="agegroup"
                                name="agegroup"
                                autoComplete="agegroup-name"
                                className="block w-full rounded-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neutral sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option value="1">{i18n.t("userform_upto")} 20</option>
                                <option value="2">21-25</option>
                                <option value="3">26-30</option>
                                <option value="4">30+</option>
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="education" className="block text-sm font-medium leading-6 text-gray-900">
                            {i18n.t("userform_education_title")}
                            </label>
                            <div className="mt-2">
                              <select
                                id="education"
                                name="education"
                                autoComplete="education-name"
                                className="block w-full rounded-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neutral sm:text-sm sm:leading-6"
                              >
                                <option value="1">{i18n.t("userform_education_bs")}</option>
                                <option value="2">{i18n.t("userform_education_ma")}</option>
                                <option value="3">{i18n.t("userform_education_ph")}</option>
                                <option value="4">{i18n.t("userform_education_integrated")}</option>
                                <option value="5">{i18n.t("userform_education_vocational")}</option>
                                <option value="0">{i18n.t("userform_education_other")}</option>
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="faculty" className="block text-sm font-medium leading-6 text-gray-900">
                            {i18n.t("userform_faculty_title")}
                            </label>
                            <div className="mt-2">
                              <select
                                id="faculty"
                                name="faculty"
                                autoComplete="faculty-name"
                                className="block w-full rounded-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-neutral sm:text-sm sm:leading-6"
                              >
                                <option value="1">{i18n.t("userform_faculty_sv")}</option>
                                <option value="2">{i18n.t("userform_faculty_hv")}</option>
                                <option value="3">{i18n.t("userform_faculty_mv")}</option>
                                <option value="4">{i18n.t("userform_faculty_lt")}</option>
                                <option value="0">{i18n.t("userform_faculty_other")}</option>
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">

                    </div>

                    <div className="">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-none border border-transparent bg-neutral px-4 py-2 text-sm font-medium text-white hover:bg-secondary hover:text-neutral focus:outline-none focus-visible:ring-2 focus-visible:ring-accent focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        {i18n.t("userform_submit")}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}