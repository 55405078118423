import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useLocalStorage } from '../../useLocalStorage';
import { allFutureSkills } from '../../utils/allScenariosFutureSkills';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

export default function SummaryFeedback() {

  const results = useLocalStorage('scenario');
  let scenerioDataArr = [];
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();


  if (localStorage.getItem("scenario") !== undefined) {
    scenerioDataArr = JSON.parse(results[0]);
  }

  function createAverages(arr) {
    let averages = [];

    allFutureSkills.forEach((futureSkill) => {
      let bucket = 0;
      let counter = 0;
      futureSkill.correlated_ids.forEach((e) => {
        bucket += scenerioDataArr[e]
        counter++;
      });

      averages.push(bucket / counter);
    })
    return averages;
  }

  let studentAverages = [3.32, 3.51, 3.56, 3.27, 3.43, 3.67, 3.25, 2.85, 3.15];

  return (
    <div className='prose max-w-none'>
      <ul className='-ml-6'>
        {createAverages(scenerioDataArr).map((futureSkill, index) => {
          return (
            <li key={allFutureSkills[index].ids} className="inline-block rounded-none bg-white border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <p className='uppercase font-bold mt-0 mb-0 text-primary'>{i18n.t(allFutureSkills[index].skill)}</p>
              <ul className="flex start-left flex-wrap pl-0 mt-0">
                {allFutureSkills[index].tags.map((tag) => {
                  return (
                    <li key={tag + index} className='badge badge-secondary bg-white mr-1 font-medium py-4 mb-0 rounded-none'>{i18n.t(tag)}</li>
                  )
                })}
              </ul>
              <p>
                {futureSkill >= studentAverages[index] ? i18n.t(allFutureSkills[index].feedback[1]) : i18n.t(allFutureSkills[index].feedback[0])}
              </p>

              <button id="careerhub-read-more-link" className='btn btn-md mb-0 bg-white hover:bg-gray-100	'>
                <a className='text-accent print:hidden uppercase font-bold flex flex-row items-center w-auto no-underline ' rel="noreferrer" target="_blank" alt={i18n.t("read_more_alt")} href={"https://sisu.ut.ee/karjaarivarav/" + i18n.t(allFutureSkills[index].link)}>
                  {i18n.t("read_more")}  <ArrowTopRightOnSquareIcon className='w-5 h-5 m-1 relative top-[-1px]'></ArrowTopRightOnSquareIcon>
                </a>
              </button>
            </li>
          )
        })
        }
      </ul>
    </div>
  );
}