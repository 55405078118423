export const allRecommendations = [
  {
    name: "s1",
    user: "Sten",
    text: "1. Kuigi Sten ei tea, mida projektipraktika raames peab tegema ning ettevõte ei ole ka väga tuttav, peaks ta leidma enda jaoks parima lahenduse. Selleks võiks Sten koguda teavet nii projektipraktika (tutvudes põhjalikult ainekavaga) kui ka ettevõtte kohta. Ta peaks enda käest küsima, mida ta tegelikult teha tahab ja milleks valmis on. <br>2.Sten peaks uuesti läbi lugema need projektid, kuhu ta vastu võeti, ja samuti praktikapakkumise ettevõttesse. Ta peaks hoolikalt läbi mõtlema ja kirja panema, mis on kõikide võimaluste „plussid“ ja „miinused“ ning mis võiks pakkuda talle kõige rohkem arenguvõimalusi. Sten peaks olema avatud ja aus ning mitte unustama, et võime endale näida paremad, kui tegelikult oleme. <br>3. Kui Sten on juba üksjagu teada saanud nii projektidest kui ettevõttest, peaks ta tegema ajakava osapooltele vastamiseks. Ta võiks proovida hinnata, kui palju aega kulub ettevõtte saadetud ülesannetele lahendamiseks, ning millal peaks ta oma otsusest teavitama projekte. <br>4. Sten võiks hoolikalt läbi mõelda, kuidas ja mida vastata. On väga oluline vastata mõlemale pakkumisele asjatundlikult, sest see annab võimaluse, et järgmisel korral võetakse temaga ise ühendust. "
  },
  {
    name: "s2",
    user: "Annika",
    text: "1. Annika peaks häid suhteid hoidma, sest ei tea, kui palju tal tulevikus veel vaja sama kolleegiga koostööd teha. Samas peab ta tagama, et ettekanne saaks väga hästi tehtud. Annika peaks tegema plaani B, et vajadusel ettekanne viimasel hetkel ise valmis teha. <br>2. Annika peaks iga hinna eest püüdma kolleegiga kontakti saavutada, et saada aru, milles probleem on. Annika peaks proovima olla empaatiline ning selgusele jõudma, miks kolleeg ei panusta. Teades neid asjaolusid, on ehk võimalik saavutada kokkulepe, kes, mida ja millal teeb. <br>3. Kui ettekanne tehtud saab, võiks Annika mõtiskleda ka selle üle, kuidas selline olukord üldse tekkis ja milline oli  tema osa selles. Annikal tuleks näiteks kaaluda, kas ta üritas piisavalt varakult koostööd algatada ja tööjaotust oma kolleegiga arutada?"
  },
  {
    name: "s3",
    user: "Kaarel",
    text: "1. Kaarel peaks kaaluma, kas minna kaasa reklaamikampaaniaga või vastanduda firma juhile, kuna  tegu on klientide eksitamisega. Ta peaks selle otsustamisel kasutama oma teadmisi sellest, kuidas see otsus võib mõjutada keskkonda ja ühiskonda laiemalt ning  tegema otsuse oma väärtushinnangutest lähtuvalt. <br>2. Kaarel peaks firma juhiga vaidlemiseks koguma asjakohast infot, et olla teadlik varasematest kaasustest, mida selline eksitav reklaamikampaania firmale kaasa võib tuua. Nii saaks argumenteeritult lahendusi otsida. <br>3. Kaarel ei peaks piirduma vaid juhi plaanide kritiseerimisega, vaid pakkuma ka uusi lahendusi. Ta võiks teha pisut taustauuringut, et leida alternatiivseid tarnijaid, kelle puhul keskkonnasõbralikkuse argumendi jätkuv kasutamine oleks õigustatud. <br>4. Lisaks eksitamise võimalike tagajärgede ning alternatiivsete tarnijate uurimisele peaks Kaarel seda infot ka kolleegidega jagama. Kaarel võiks teha juhile ettepaneku kutsuda kokku meeskond töötajatetest, kelle tegevust tarnija leidamine ja reklaamikampaania kõige lähemalt puudutab, et võimalikke probleeme ja lahendusi nendega arutada."
  },
  {
    name: "s4",
    user: "Maria",
    text: "1. Esimese töökuu võiks Maria veeta eri töötajate gruppide töövarjuna, jälgides näiteks ühe päeva jooksul, milline on nende töörütm, ja küsides nende käest otse, millist koolitust nad enim vajaksid ja millises vormis see peaks olema. Selline lähenemine on küll ajakulukas ja Maria ei jõua kõigi 800 inimeseni, ent ta saab üsna adekvaatse tunnetuse, mida inimesed päriselt vajaksid. <br>2. Maria peaks oma juhiga leppima kokku kohtumise, et teada saada, millised on juhi arvates koolituste osas suurimad arenguvajadused ja mis on ettevõtte jaoks hetkel suurimad valupunktid. Juhi arvamusest lähtudes peaks Maria püüdma leida peaks Maria koostama plaani, kellele, millal ja milliseid koolitusi korraldada. <br>3. Maria võiks liituda koolitusjuhtide kogukonnaga, et saada sealt häid ideid ja mõtteid,kuidas kõige paremini koolitusvajadused kaardistada.<br>4. Maria peaks uurima viimase viie aasta koolituste statistikat ja analüüsima tagasisidet. Analüüsist lähtuvalt vaatama üle praeguse koolitusplaani ja otsima turult uusi koolitusi, mis on töötajate arenguks olulised."
  },
  {
    name: "s5",
    user: "Rasmus",
    text: "1. Rasmusel on seitsmest soovitavad oskusest olemas neli. Intervjuul peaks ta olema aus oma oskuste suhtes, samas rõhutama oma kiiret õppimisvõimet ja esitlema, kuidas kavatseb vajalikud oskused omandada. <br>2. Kuna töökoht on atraktiivne ja sellele võib oodata rohkelt kandidaate, siis on oluline teiste seast silma paista. Selleks peaks Rasmus esitama uuenduslikuma videovormis CV, kuigi tavalise CV koostamine võtab vaid tunnikese, videovormis CV loomiseks kulub oluliselt rohkem aega. <br>3. Rasmus peaks uskuma, et saab videovormis CV koostamisega hakkama. Ta peaks veebist otsima selle kohta õpetusi ning koostama originaalse sisu ja lahendusega video. <br>4. Rasmus peaks olema leidlik ja mõtlema „kastist välja“ ning mõtlema CV koostamise peale, mis mõjuks uudse ja originaalsena."
  },
  {
    name: "s6",
    user: "Anna",
    text: "1. Paari kuu pärast toimuval iga-aastasel arenguvestlusel peaks Anna palgateemast juhiga rääkima. Ta peaks küsima palgatõusu. <br>2. Anna peaks hoolikalt läbi mõtlema, kuidas arenguvestlusel palka juurde küsida. Ta peaks lugema soovitusi, kuidas palgatõusu küsimisel näidata oma väärtuslikkust ettevõtte jaoks. <br>3. Anna peaks otsima proaktiivselt võimalusi, kuidas ise töö juures veel paremaks saada, mida võiks juurde õppida. <br>4. Anna peaks juhile avameelselt selgitama, et uue töötaja palganumber tekitab teistes töötajates ebaõiglustunde. Ta peaks vestlusel märku andma, et töötajate võrdne ja õiglane kohtlemine on kõigi huvides. "
  },
  {
    name: "s7",
    user: "Laura",
    text: "1. Laura peaks suhteliselt täpselt hindama, kui palju kulub lõputöö koostamiseks ja kaitsmiseks aega. Ta peaks koostama ajakava täiskoormusega töö ja lõputöö sobitamiseks ning otsustama, millest ta peab loobuma. <br>2. Laura peaks oma probleemi lahendamiseks nõu küsima nii oma headelt  sõpradelt kui ka paarilt ülikooli õppejõult, kes teda paremini tunnevad. Eri inimeste mõtetest võivad tekkida lahendused, mille peale ta üksi ei tuleks. <br>3. Laura on endale selgeks teinud, kuidas kasutada digikeskkondi lõputöö tegemiseks (allikate ostimiseks, analüüsimiseks, vormistamiseks jne) kasutada, mistõttu võiks ta eeldada, et lõputöö tegemisega saab ta ka töövälisel ajal edukalt hakkama. <br>4. Kuna Laura lõputöö on seotud tihedalt ettevõtte tegevusvaldkonnaga (keskkonnaalane jätkusuutlikkus), siis ta peaks oma lõputöös välja pakkuma ettepanekud selles valdkonnas edukamaks tegututsemiseks ning teadvustama seda tööandjale."
  },
  {
    name: "s8",
    user: "Kristjan",
    text: "1. Kristjan peaks meeskonnale kohe teadma andma, et tema arvutil on tehnilised probleemid, mis ta lahendab võimalikult kiiresti ning võimalusel paluma alustada nõupidamist oma kolleegil. <br>2. Kristjan peaks kiiresti mõnelt selle platvormiga rohkem töötanud kolleegilt kas veebi kaudu või telefoni teel kiiresti abi küsima. Kindlasti peaks Kristjan meeskonnale teada andma, et mikrofon ei tööta, aga ta kuuleb neid ja püüab kiiresti lahenduse leida. <br>3. Kristjan peaks proovima koosoleku linki ka oma telefonis avada (äkki seal mikrofon töötab).  Igal juhul peaks ta jutuakna kaudu teistele teatama, et mikrofon ei tööta, aga ta kuuleb neid ja püüab kiiresti lahenduse leida. <br>4. Kristjan peaks ise arvuti seadetest uurima, kuidas mikrofoni sisendeid muuta, ja võimalikke lahendusi guugeldama. Igal juhul peaks ta jutuakna kaudu teistele teatama, et mikrofon ei tööta, aga ta kuuleb neid ja püüab kiiresti lahenduse leida. "
  },
];
