
    import { Fragment } from "react";
    import { Dialog, Transition } from '@headlessui/react';
    import i18n from 'i18next';
    import { Trans } from 'react-i18next'
    
    export default function InfoModal( {isOpen, setIsOpen} ) {
    
      function closeModal() {
        setIsOpen(false);
      }
    
      return (
        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" id="infoModal" onClose={() => {}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
    
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="prose max-w-7xl transform overflow-hidden rounded-none bg-white p-6 text-justify align-middle shadow-xl transition-all select-none">
                      {/*
                      <Dialog.Title
                        as="h3"
                        className="font-bold leading-6 !text-accent uppercase"
                      >
                        {i18n.t("intro_modal_title")}
                      </Dialog.Title> 
                      */}
                      <div className="mt-2">
                      </div>
    
                        <div className="space-y-12">
                          <div className="border-b border-gray-900/10 pb-6 leading-6">
                            <Trans i18nKey="intro_modal_text"></Trans>
                          </div>
                        </div>
    
                        <div className="mt-6 flex items-center justify-end gap-x-6">
    
                        </div>
    
                        <div className="">
                          <button
                            className="inline-flex justify-center rounded-none text-white border-transparent btn-primary px-4 py-2 font-medium uppercase hover:btn-secondary focus:outline-none"
                            onClick={closeModal} tabIndex="-1"
                          >
                            {i18n.t("intro_modal_submit")}
                          </button>
                        </div>
    
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      );
    }