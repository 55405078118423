export const allScenarios = [
  {
    name: "s1",
    person: "Sten",
    text: "s1_text",
    recommendations: [
      "s1_recommendation_1",
      "s1_recommendation_2",
      "s1_recommendation_3",
      "s1_recommendation_4"
    ],
    selfevaluations: [
      "s1_decision_1",
      "s1_decision_2",
      "s1_decision_3",
      "s1_decision_4"
    ]
  },
  {
    name: "s2",
    person: "Annika",
    text: "s2_text",
    recommendations: [
      "s2_recommendation_1",
      "s2_recommendation_2",
      "s2_recommendation_3",
      "s2_recommendation_4"
    ],
    selfevaluations: [
      "s2_decision_1",
      "s2_decision_2",
      "s2_decision_3",
      "s2_decision_4"
    ]
  },
  {
    name: "s3",
    person: "Kaarel",
    text: "s3_text",
    recommendations: [
      "s3_recommendation_1",
      "s3_recommendation_2",
      "s3_recommendation_3",
      "s3_recommendation_4",
      "s3_recommendation_5"
    ],
    selfevaluations: [
      "s3_decision_1",
      "s3_decision_2",
      "s3_decision_3",
      "s3_decision_4",
      "s3_decision_5"
    ]
  },
  {
    name: "s4",
    person: "Maria",
    text: "s4_text",
    recommendations: [
      "s4_recommendation_1",
      "s4_recommendation_2",
      "s4_recommendation_3",
      "s4_recommendation_4",
      "s4_recommendation_5"
    ],
    selfevaluations: [
      "s4_decision_1",
      "s4_decision_2",
      "s4_decision_3",
      "s4_decision_4",
      "s4_decision_5"
    ]
  },
  {
    name: "s5",
    person: "Rasmus",
    text: "s5_text",
    recommendations: [
      "s5_recommendation_1",
      "s5_recommendation_2",
      "s5_recommendation_3",
      "s5_recommendation_4",
      "s5_recommendation_5"
    ],
    selfevaluations: [
      "s5_decision_1",
      "s5_decision_2",
      "s5_decision_3",
      "s5_decision_4",
      "s5_decision_5"
    ]
  },
  {
    name: "s6",
    person: "Anna",
    text: "s6_text",
    recommendations: [
      "s6_recommendation_1",
      "s6_recommendation_2",
      "s6_recommendation_3",
      "s6_recommendation_4",
      "s6_recommendation_5"
    ],
    selfevaluations: [
      "s6_decision_1",
      "s6_decision_2",
      "s6_decision_3",
      "s6_decision_4",
      "s6_decision_5"
    ]
  },
  {
    name: "s7",
    person: "Laura",
    text: "s7_text",
    recommendations: [
      "s7_recommendation_1",
      "s7_recommendation_2",
      "s7_recommendation_3",
      "s7_recommendation_4"
    ],
    selfevaluations: [
      "s7_decision_1",
      "s7_decision_2",
      "s7_decision_3",
      "s7_decision_4"
    ]
  },
  {
    name: "s8",
    person: "Kristjan",
    text: "s8_text",
    recommendations: [
      "s8_recommendation_1",
      "s8_recommendation_2",
      "s8_recommendation_3",
      "s8_recommendation_4"
    ],
    selfevaluations: [
      "s8_decision_1",
      "s8_decision_2",
      "s8_decision_3",
      "s8_decision_4"
    ]
  },
];
