import { Fade } from "react-awesome-reveal";
import { useEffect, useState } from "react";

export default function Help() {

  const [futulabPage, setFutulabPage] = useState('');
  const fetchFutulabPage = async () => {
    try {
      const response = await fetch('https://futulab.ut.ee/wp-json/wp/v2/pages/14806', {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFutulabPage(data.content.rendered);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    fetchFutulabPage();
  }, []);


  return(
    <Fade>
      <div id="" className="m-10 prose max-w-none">
        <div dangerouslySetInnerHTML={{__html: futulabPage}} />
      </div>
    </Fade>
  );
}