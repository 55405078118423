import { useRouteError, Link } from "react-router-dom";
import i18n from 'i18next';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <div id="hero" className="hero min-h-screen bg-base-200">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-5xl font-bold">{i18n.t("error_title")}</h1>
            <p className="py-6">
              {i18n.t("error_text")}
            </p>
            <p><i>{error.statusText || error.message}</i></p>
            <Link to="/scenarios/#">
              <button className="btn btn-primary">
                {i18n.t("error_submit")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}