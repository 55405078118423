export const allScenariosDecisions = [
  {
    id: "s1_1",
    decision: "s1_decision_1"
  },
  {
    id: "s1_2",
    decision: "s1_decision_2"
  },
  {
    id: "s1_3",
    decision: "s1_decision_3"
  },
  {
    id: "s1_4",
    decision: "s1_decision_4"
  },
  {
    id: "s2_1",
    decision: "s2_decision_1"
  },
  {
    id: "s2_2",
    decision: "s2_decision_2"
  },
  {
    id: "s2_3",
    decision: "s2_decision_3"
  },
  {
    id: "s2_4",
    decision: "s2_decision_4"
  },
  {
    id: "s3_1",
    decision: "s3_decision_1"
  },
  {
    id: "s3_2",
    decision: "s3_decision_2"
  },
  {
    id: "s3_3",
    decision: "s3_decision_3"
  },
  {
    id: "s3_4",
    decision: "s3_decision_4"
  },
  {
    id: "s3_5",
    decision: "s3_decision_5"
  },
  {
    id: "s4_1",
    decision: "s4_decision_1"
  },
  {
    id: "s4_2",
    decision: "s4_decision_2"
  },
  {
    id: "s4_3",
    decision: "s4_decision_3"
  },
  {
    id: "s4_4",
    decision: "s4_decision_4"
  },
  {
    id: "s4_5",
    decision: "s4_decision_5"
  },
  {
    id: "s5_1",
    decision: "s5_decision_1"
  },
  {
    id: "s5_2",
    decision: "s5_decision_2"
  },
  {
    id: "s5_3",
    decision: "s5_decision_3"
  },
  {
    id: "s5_4",
    decision: "s5_decision_4"
  },
  {
    id: "s5_5",
    decision: "s5_decision_5"
  },
  {
    id: "s6_1",
    decision: "s6_decision_1"
  },
  {
    id: "s6_2",
    decision: "s6_decision_2"
  },
  {
    id: "s6_3",
    decision: "s6_decision_3"
  },
  {
    id: "s6_4",
    decision: "s6_decision_4"
  },
  {
    id: "s6_5",
    decision: "s6_decision_5"
  },
  {
    id: "s7_1",
    decision: "s7_decision_1"
  },
  {
    id: "s7_2",
    decision: "s7_decision_2"
  },
  {
    id: "s7_3",
    decision: "s7_decision_3"
  },
  {
    id: "s7_4",
    decision: "s7_decision_4"
  },
  {
    id: "s8_1",
    decision: "s8_decision_1"
  },
  {
    id: "s8_2",
    decision: "s8_decision_2"
  },
  {
    id: "s8_3",
    decision: "s8_decision_3"
  },
  {
    id: "s8_4",
    decision: "s8_decision_4"
  },
];
