/* Special case - does not need i18n function here */
/* Will add this in component */
export const checkboxOptions = [
  {
    name: "o4",
    value: 4
  },
  {
    name: "o3",
    value: 3
  },
  {
    name: "o2",
    value: 2
  },
  {
    name: "o1",
    value: 1
  }
];
